//  This shared module contains all the ui-generator modules.
//  Follow below steps to understand and use these modules inorder to minimize build size:
//  1. AxisUiGeneratorAllModule contains all AUG modules including core, form, components, charts, abc and grid modules.
//     If you want to use all, just uncomment this.
//  2. If you are not using all modules, then import only those required modules along with core and form module.
//  3. For Ex: you are only using components, then you can only import AxisUiGeneratorComponentsModule along with
//     AxisUiGeneratorCoreModule and AxisUiGeneratorFormModule.
//  4. To add axis-ui-generator dependency(9.x) and its peer dependencies in your project, Use axis-cli command - axis add ui-generator.
//  5 . import this module in your app.module.ts to use AUG features.

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

// AXIS UI GENERATOR
// import { AxisUiGeneratorAllModule } from 'axis-ui-generator';
   import { AxisUiGeneratorCoreModule } from 'axis-ui-generator';
   import { AxisUiGeneratorFormModule } from 'axis-ui-generator';
   import { AxisUiGeneratorComponentsModule } from 'axis-ui-generator';
// import { AxisUiGeneratorChartsModule } from 'axis-ui-generator';
// import { AxisUiGeneratorAbcsModule } from 'axis-ui-generator';
// import { AxisUiGeneratorDatagridModule } from 'axis-ui-generator';
import { CDPTextareaControlModule } from "./cdp-textarea-control/cdp-textarea-control.module";
const AUG_MODULES = [  
  // AxisUiGeneratorAllModule,
  // AxisUiGeneratorChartsModule,
  // AxisUiGeneratorAbcsModule,
  // AxisUiGeneratorDatagridModule,
     CDPTextareaControlModule,
     AxisUiGeneratorCoreModule,
     AxisUiGeneratorFormModule,
     AxisUiGeneratorComponentsModule, 
     CommonModule
];

// AUG SHARED MODULE
@NgModule({
  imports: [...AUG_MODULES],
  declarations: [],
  exports: [...AUG_MODULES]
})
export class AxisSharedAugModule {}
