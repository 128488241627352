import { Injectable } from "@angular/core";
import { DataStoreService } from "../../services/data-store.service";
import { FnolNordicsApiService } from "./common-nordics-api-services";
import { Utils } from "axis-ui-generator";
import { Configuration } from "src/app/shared/configuration";
import { CacheService } from "src/app/shared/services/cache.service";
import * as moment from 'moment';
import { dataArray, landingPageLabels } from "../constants/fnol-norway-travel.constant";
import { AxisInputNumberPipe } from "axis-components";

@Injectable({
  providedIn: 'root'
})
export class FnolNordicsDeclarationService {

   languagecheck= window.location.pathname.split('/')[4];
  language: string;
  constructor(public axisPipe : AxisInputNumberPipe,private dataStoreService: DataStoreService, private config: Configuration,  private cacheService: CacheService, private nordicsApiService: FnolNordicsApiService) {

  }
  uiStructure;
  uiData;
  selectedData;
  delayLossData;
  mySettings51: any = {
    view_roundoff: false,
    thousands_separator: ".",
    decimal_separator: ",",
    fraction_size: "2",
    reg_exp: "^[\.0-9]*$",
    decimal: "",
    restrict: false
   };
  claimTypeDetails = { "tripDetails": [], "dammage": [], "baggageDelay": [], "theft": [], "rental": [], "medical": [], "cancel": [], "tripDelay": [], "claimAmount": [] };
  claimDetailsInfo;
  lossTypesData;
  getData(name, type, stepName) {
    this.uiStructure = this.dataStoreService.get(stepName).uiStructure;
    this.uiData = this.dataStoreService.get(stepName).uiData;
    const checkBoxFlag = Utils.getFieldFromStructure(this.uiStructure, name);
    if (type == "label") {
      const resultData = Utils.getFieldFromStructure(this.uiStructure, name);
      if(resultData?.type === "checkbox"){
          return {
            label:resultData?.heading,
            questionCd: resultData?.questionCd,
            heading:resultData?.heading
          }
      }
      return resultData;
    } else {
      let language= window.location.pathname.split('/')[4];
     if(language  === "en"){
      const resultData = Utils.getFieldFromStructure(this.uiStructure, name);
      if(resultData?.type === "datepicker" &&  this.uiData[name] != 'Invalid date'  &&  this.uiData[name] != ''){
        return  (!this.uiData[name]) ?'': moment(new Date(this.uiData[name])).format('DD-MM-YYYY');
      }else if(resultData?.type === "timepicker"){
       return (this.uiData[name]!=null && this.uiData[name]!="") &&this.uiData[name]?.length>5  ? moment(this.uiData[name]).format("HH:mm"):'';
      }else if(resultData?.type === "checkbox"){
        return (this.uiData[name] != null && this.uiData[name] != '' && this.uiData[name] != 'No' ) ? "Yes" : "No"
      }else{
        return this.uiData[name]
      }

     }
      else if(language === "no" &&  Utils.getFieldFromStructure(this.uiStructure, name) && Utils.getFieldFromStructure(this.uiStructure, name).hasOwnProperty('translatedRequired') )
   {
    const resultData = Utils.getFieldFromStructure(this.uiStructure, name);
     console.log(resultData);
     if(this.uiData[name]!=null)
      return resultData.options.filter(e => e.value === this.uiData[name])[0]['label']
    }
      else if(language === "no" && checkBoxFlag?.type === 'checkbox') {	 	   	 	 
      return (this.uiData[name] != null && this.uiData[name] != '' && this.uiData[name] != 'No' ) ? "Ja" : "Nei"
      }
      else{
        const resultData = Utils.getFieldFromStructure(this.uiStructure, name);
        if(resultData?.type === "datepicker" &&  this.uiData[name] != 'Invalid date'  &&  this.uiData[name] != ''){
          return (!this.uiData[name]) ?'': moment(new Date(this.uiData[name])).format('DD-MM-YYYY');
        }else if(resultData?.type === "timepicker"){
         return (this.uiData[name]!=null && this.uiData[name]!="") &&this.uiData[name]?.length>5  ? moment(this.uiData[name]).format("HH:mm"):'';
        }
        else{
          if(resultData  && resultData.type === "addressLookup" && typeof this.uiData[name] === 'object' ){
            return this.uiData[name]['viewModel'] ||  this.uiData[name]['name']
          } else{
            return this.uiData[name];

          }
        }




      }

    }
  }
  step1Name = 'fnol-claimant-info';
  step2Name = 'fnol-claimtype-details';
  step3Name = 'fnol-payment-details';

  getCreditCard() {
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    let creditCardInfoDetails = []
    let creditCardInfo = ["chooseYourself", "nameBank", "cardType", "creditCardPrimary", "creditCard4", "transportation", "cardholder"]

    creditCardInfo.forEach(res => {
      if(this.getData(res, "label", this.step1Name)['type'] ==="dropdown" ){
       creditCardInfoDetails.push(
        {
          "questionText": this.getData(res, "label", this.step1Name)['label'],
          "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
          "answerCd":this.getData(res, "", this.step1Name).rpaValue,

          "answer": this.getData(res, "", this.step1Name).name
        })
      }
     else {
      creditCardInfoDetails.push(
        {
          "questionText": this.getData(res, "label", this.step1Name)['label'],
          "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
          "answerCd": null,
          "answer": this.getData(res, "", this.step1Name)
        })
        }
    })
    if (uiData['cardholder'] === "No") {
      let cardHoldNo = uiData['relationship'].id !== 8 ? ["firstName", "lastName", "relationship"] : ["firstName", "lastName", "relationship", "relationshipOthers"];
      cardHoldNo.forEach(res => {

        if(res === "relationship"){
          creditCardInfoDetails.push(
            {
              "questionText": this.getData(res, "label", this.step1Name)['label'],
              "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
              "answerCd":this.getData(res, "", this.step1Name)?.rpaValue,
              "answer": this.getData(res, "", this.step1Name).name
            })

        } else {
          creditCardInfoDetails.push(
            {
              "questionText": this.getData(res, "label", this.step1Name)['label'],
              "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
              "answerCd":null,
              "answer": this.getData(res, "", this.step1Name)
            })
        }

      });

    }

    creditCardInfoDetails.push(
      {
        "questionText": this.getData("participate", "label", this.step1Name)['label'],
        "questionCd": this.getData("participate", "label", this.step1Name)['questionCd'],
        "answerCd": null,
        "answer": this.getData("participate", "", this.step1Name)
      }
    )

    if (uiData['travel'] === "Yes") {
      creditCardInfoDetails.push( {
        "questionText": $localize`:@@doYouTravel:Do you or your travel companions have other travel insurance?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd": null,
        "answer": this.getData("travel", "", this.step1Name),
        "additionalInfo": this.getCreditTravel() })

    }else{
      creditCardInfoDetails.push( {
        "questionText": $localize`:@@doYouTravel:Do you or your travel companions have other travel insurance?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd": null,
        "answer":this.getData("travel", "", this.step1Name)
    })
  }

    creditCardInfoDetails.push({
      "questionText": this.getData("assistance", "label", this.step1Name)['label'],
      "questionCd": this.getData("assistance", "label", this.step1Name)['questionCd'],
      "answerCd": null,
      "answer": this.getData("assistance", "", this.step1Name)

    })
    if (uiData['assistance'] === "Yes") {

      creditCardInfoDetails.push(
        {
          "questionText": this.getData("alarmNameCredit", "label", this.step1Name)['label'],
          "questionCd": this.getData("alarmNameCredit", "label", this.step1Name)['questionCd'],
          "answerCd": null,
          "answer": this.getData("alarmNameCredit", "", this.step1Name)
        },{
          "questionText": this.getData("claimCheck", "label", this.step1Name)['heading'],
          "questionCd": this.getData("claimCheck", "label", this.step1Name)['questionCd'],
          "answerCd": null,
          "answer": this.getData("claimCheck", "", this.step1Name)
        })

    }

    return creditCardInfoDetails;

  }

  getCreditTravel(){
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "addTravelMain-Yes").fields
    let travelMainArr = []
    travelInsurance.forEach((data, i) => {
      let travelArr=[]
      const index = i > 0 ? i+1 : "";
      const labelIndex = index!=""?(index-1):index;
      const nonBagNoEMp = ["companyNameCredit", "policyNameCredit", "typePolicyCredit","policyCheck"];
      nonBagNoEMp.forEach((item, i) => {
        if(this.getData(item + index, "label", this.step1Name)["type"] == "checkbox" ){
          travelArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['heading'],
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": null,
            "answer": this.getData(item + index, "", this.step1Name)
          });

        }else{
          travelArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['label'] ,
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": null,
            "answer": this.getData(item + index, "", this.step1Name)
          });
        }

      });
      travelMainArr.push({
        "featureTypeText": $localize`:@@addInfor:Additional Insurance #` + labelIndex,
        "featureTypeCd": "additionalInsurance" + labelIndex,
        "questionAnswer": travelArr
      })
    })
    return travelMainArr
  }

  getUnionInfo() {
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    let unionDetails = ["chooseYourself","creditCardAssociation", "creditCard4Association", "creditCardPolicyNumber", "unionPurchased"];
    let unionDetailsInfo = [];
    unionDetails.forEach(res => {
      unionDetailsInfo.push({
        "questionText": this.getData(res, "label", this.step1Name)['label'],
        "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
        "answerCd": null,
        "answer": this.getData(res, "", this.step1Name)
      })
    })

    if (uiData['unionPurchased'] === "Yes") {
      let cardHoldNo = ["nameBankUnion", "cardTypeUnion", "creditCardUnion", "creditCard4Union"];
      cardHoldNo.forEach(res => {
        if(res === 'cardTypeUnion'){
          unionDetailsInfo.push(
            {
              "questionText": this.getData(res, "label", this.step1Name)['label'],
              "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
              "answerCd":this.getData(res, "", this.step1Name)?.rpaValue,
              "answer": this.getData(res, "", this.step1Name).name

            })
        } else {
          unionDetailsInfo.push(
            {
              "questionText": this.getData(res, "label", this.step1Name)['label'],
              "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
              "answerCd": null,
              "answer": this.getData(res, "", this.step1Name)

            })
        }


      });

    }

    if (uiData['unionInsurance'] === "Yes") {
      unionDetailsInfo.push({
        "questionText": $localize`:@@doYouTravel:Do you or your travel companions have other travel insurance?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd": null,
        "answer": this.getData("unionInsurance", "", this.step1Name),
        "additionalInfo": this.getUnionTravel() })

    }else{
      unionDetailsInfo.push({
        "questionText": $localize`:@@doYouTravel:Do you or your travel companions have other travel insurance?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd": null,
        "answer": this.getData("unionInsurance", "", this.step1Name)
       })

    }
    unionDetailsInfo.push({
      "questionText": this.getData("unionIncident", "label", this.step1Name)['label'],
      "questionCd": this.getData("unionIncident", "label", this.step1Name)['questionCd'],
      "answerCd": null,
      "answer": this.getData("unionIncident", "", this.step1Name)

    })
    if (uiData['unionIncident'] === "Yes") {

      unionDetailsInfo.push(
        {
          "questionText": this.getData("alarmName", "label", this.step1Name)['label'],
          "questionCd": this.getData("alarmName", "label", this.step1Name)['questionCd'],
          "answerCd": null,
          "answer": this.getData("alarmName", "", this.step1Name)
        },
        {
          "questionText": this.getData("numberCheck", "label", this.step1Name)['heading'],
          "questionCd": this.getData("numberCheck", "label", this.step1Name)['questionCd'],
          "answerCd": null,
          "answer": this.getData("numberCheck", "", this.step1Name)
        }
        )

    }
    return unionDetailsInfo;
  }

  getUnionTravel(){
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "addUnionInsuranceMain-Yes").fields
    let travelMainArr = []
    travelInsurance.forEach((data, i) => {
      let travelArr=[]
      const index = i > 0 ? i+1 : "";
      const labelIndex = index!=""?(index-1):index;
      const nonBagNoEMp = ["companyNameUnion", "policyNameUnion", "typePolicyUnion","insuranceCheck"];
      nonBagNoEMp.forEach((item, i) => {
        if(this.getData(item + index, "label", this.step1Name)["type"] == "checkbox" ){
          travelArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['heading'] ,
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": null,
            "answer": this.getData(item + index, "", this.step1Name)
          });

        }else{
          travelArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['label'] ,
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": null,
            "answer": this.getData(item + index, "", this.step1Name)
          });
        }

      });
      travelMainArr.push({
        "featureTypeText": $localize`:@@addInfor:Additional Insurance #` + labelIndex,
        "featureTypeCd": "additionalInsurance" + labelIndex,
        "questionAnswer": travelArr
      })
    })
    return travelMainArr
  }

  getMyself() {
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    let myselfDetails = ["chooseYourself","policyNumber","purchasedTrip", "purchasedCredit"];
    let myselfDetailsInfo = [];
    myselfDetails.forEach(res => {
      myselfDetailsInfo.push(
        {
          "questionText": this.getData(res, "label", this.step1Name)['label'],
          "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
          "answer": this.getData(res, "", this.step1Name),
          "answerCd":null,
        })
    })
    if (uiData['purchasedCredit'] === "Yes") {
      let cardHoldNo = ["nameBankPurchased", "cardTypePurchased", "creditCardPurchased", "creditCard4Purchased"];
      cardHoldNo.forEach(res => {
        if(res ==="cardTypePurchased"){
          myselfDetailsInfo.push(
            {
              "questionText": this.getData(res, "label", this.step1Name)['label'],
              "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
              "answerCd":this.getData(res, "", this.step1Name)?.rpaValue,
              "answer": this.getData(res, "", this.step1Name).name

            })
        }  else {

          myselfDetailsInfo.push(
            {
              "questionText": this.getData(res, "label", this.step1Name)['label'],
              "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
              "answerCd":null,
              "answer": this.getData(res, "", this.step1Name)
            })
        }

      })

    }

    if (uiData['purchasedInsurance'] === "Yes") {
      myselfDetailsInfo.push({
        "questionText": $localize`:@@doYouTravel:Do you or your travel companions have other travel insurance?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd": null,
        "answer":this.getData("purchasedInsurance", "", this.step1Name),
        "additionalInfo": this.getMyselfTravel()
      })
    }else{
      myselfDetailsInfo.push({
        "questionText": $localize`:@@doYouTravel:Do you or your travel companions have other travel insurance?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd": null,
        "answer": this.getData("purchasedInsurance", "", this.step1Name),
      })
    }

    myselfDetailsInfo.push({
      "questionText": this.getData("purchasedIncident", "label", this.step1Name)['label'],
      "questionCd": this.getData("purchasedIncident", "label", this.step1Name)['questionCd'],
      "answerCd": null,
      "answer": this.getData("purchasedIncident", "", this.step1Name)

    })
    if (uiData['purchasedIncident'] === "Yes") {

      myselfDetailsInfo.push(
        {
          "questionText": this.getData("alarmNameMyself", "label", this.step1Name)['label'],
          "questionCd": this.getData("alarmNameMyself", "label", this.step1Name)['questionCd'],
          "answerCd": null,
          "answer": this.getData("alarmNameMyself", "", this.step1Name)
        },{
          "questionText": this.getData("policyCheckDetails", "label", this.step1Name)['heading'],
          "questionCd": this.getData("policyCheckDetails", "label", this.step1Name)['questionCd'],
          "answerCd": null,
          "answer": this.getData("policyCheckDetails", "", this.step1Name)
        })

    }
    return myselfDetailsInfo

  }

getMyselfTravel(){
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "addPurchasedInsuranceMain-Yes").fields
    let travelMainArr = []
    travelInsurance.forEach((data, i) => {
      let travelArr=[]
      const index = i > 0 ? i+1 : "";
      const labelIndex = index!=""?(index-1):index;
      const nonBagNoEMp = ["companyNameMyself", "policyNameMyself", "typePolicyMyself","policyCheckMyself"];
      nonBagNoEMp.forEach((item, i) => {
        if(this.getData(item + index, "label", this.step1Name)["type"] == "checkbox" ){
          travelArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['heading'] ,
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": null,
            "answer": this.getData(item + index, "", this.step1Name)
          });

        }else{
          travelArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['label'] ,
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": null,
            "answer": this.getData(item + index, "", this.step1Name)
          });
        }

      });
      travelMainArr.push({
        "featureTypeText": $localize`:@@addInfor:Additional Insurance #` + labelIndex,
        "featureTypeCd": "additionalInsurance" + labelIndex,
        "questionAnswer": travelArr
      })
    })
    return travelMainArr
  }

  getEmployer() {
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    let employeeDetails = ["chooseYourself","nameEmployer", "nameEmployee", "emailAddress", "creditCardEmployer", "employmentType","creditEmployer"];
    let employerDetailsInfo = [];
    employeeDetails.forEach(res => {
      employerDetailsInfo.push(
        {
          "questionText": this.getData(res, "label", this.step1Name)['label'],
          "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
          "answer": this.getData(res, "", this.step1Name),
          "answerCd":null,
        })
    })
    if (uiData['creditEmployer'] === "Yes") {
      let cardHoldNo = ["nameBankEmployer", "cardTypeEmployer", "creditCard6Employer", "creditCard4Employer"];
      cardHoldNo.forEach(res => {
        if(res === "cardTypeEmployer" ){
        employerDetailsInfo.push(
          {
            "questionText": this.getData(res, "label", this.step1Name)['label'],
            "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
            "answerCd":this.getData(res, "", this.step1Name)?.rpaValue,
            "answer": this.getData(res, "", this.step1Name).name
          })}
          else {
            employerDetailsInfo.push(
              {
                "questionText": this.getData(res, "label", this.step1Name)['label'],
                "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
                "answerCd":null,
                "answer": this.getData(res, "", this.step1Name)
              })
          }
      })

    }


    if (uiData['insuranceEmployer'] === "Yes") {
      employerDetailsInfo.push({
        "questionText": $localize`:@@doYouTravel:Do you or your travel companions have other travel insurance?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd": null,
        "answer": this.getData("insuranceEmployer", "", this.step1Name),
        "additionalInfo": this.getEmployerTravel() })
    } else{
      employerDetailsInfo.push({
        "questionText": $localize`:@@doYouTravel:Do you or your travel companions have other travel insurance?`,
        "questionCd": "IsThereOtherTravelInsurance",
        "answerCd": null,
        "answer": this.getData("insuranceEmployer", "", this.step1Name),
       })
    }

    employerDetailsInfo.push({
      "questionText": this.getData("incidentEmployer", "label", this.step1Name)['label'],
      "questionCd": this.getData("incidentEmployer", "label", this.step1Name)['questionCd'],
      "answerCd": null,
      "answer": this.getData("incidentEmployer", "", this.step1Name)

    })
    if (uiData['incidentEmployer'] === "Yes") {

      employerDetailsInfo.push(
        {
          "questionText": this.getData("alarmNameEmployer", "label", this.step1Name)['label'],
          "questionCd": this.getData("alarmNameEmployer", "label", this.step1Name)['questionCd'],
          "answerCd": null,
          "answer": this.getData("alarmNameEmployer", "", this.step1Name)
        },
        {
          "questionText": this.getData("claimPolicyCheck", "label", this.step1Name)['heading'],
          "questionCd": this.getData("claimPolicyCheck", "label", this.step1Name)['questionCd'],
          "answerCd": null,
          "answer": this.getData("claimPolicyCheck", "", this.step1Name)
        })

    }
    return employerDetailsInfo
  }

  getEmployerTravel(){
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "addInsuranceEmployerMain-Yes").fields
    let travelMainArr = []
    travelInsurance.forEach((data, i) => {
      let travelArr=[]
      const index = i > 0 ? i+1 : "";
      const labelIndex = index!=""?(index-1):index;
      const nonBagNoEMp = ["companyNameEmployer", "policyNameEmployer", "typePolicyEmployer","policyCheckEmployer"];
      nonBagNoEMp.forEach((item, i) => {
        if(this.getData(item + index, "label", this.step1Name)["type"] == "checkbox" ){
          travelArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['heading'] ,
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": null,
            "answer": this.getData(item + index, "", this.step1Name)
          });

        }else{
          travelArr.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['label'] ,
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": null,
            "answer": this.getData(item + index, "", this.step1Name)
          });
        }

      });
      travelMainArr.push({
        "featureTypeText": $localize`:@@addInfor:Additional Insurance #` + labelIndex,
        "featureTypeCd": "additionalInsurance" + labelIndex,
        "questionAnswer": travelArr
      })
    })
    return travelMainArr
  }


  getPersonalObj(){
    let personalInfo = ["personalFirstName", "personalLastName", "personalEmail"];
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    let personalDetailsInfo = [];
    personalInfo.forEach(res => {
      personalDetailsInfo.push(
        {
          "questionText": this.getData(res, "label", this.step1Name)['label'],
          "questionCd": this.getData(res, "label", this.step1Name)['questionCd'],
          "answerCd":null,

          "answer": this.getData(res, "", this.step1Name)
        })
    })

    personalDetailsInfo.push(
      {
        "questionText": $localize`:@@phoneNumberNor:Phone Number`,
        "questionCd":  "phoneNumber",
        "answerCd":null,
        "answer": this.getData("personalPhone", "", this.step1Name)['phoneCode'].hasOwnProperty('label')? this.getData("personalPhone", "", this.step1Name)['phoneCode']['label']+ "-"  + this.getData("personalPhone", "", this.step1Name)['phoneNumber'] : this.getData("personalPhone", "", this.step1Name)['phoneCode'] + "-"  + this.getData("personalPhone", "", this.step1Name)['phoneNumber']
      })
      personalDetailsInfo.push(
        {
          "questionText": $localize`:@@iApprovemail:Do you approve to receive communication by email?`,
         "questionCd":  "emailCommunication",
          "answerCd": null,
          "answer":  this.dataStoreService.get(this.step1Name).uiData['personalCheck'] === "Yes"? $localize`:@@yesNor:Yes`: $localize`:@@noNor:No`
        })

        return personalDetailsInfo
  }

  getPersonalContainerVal(){
    let uiData = this.dataStoreService.get(this.step1Name).uiData;

    let SelectPersonArr=[]
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "addPersonMain").fields
    travelInsurance.forEach((data, i) => {
      let personalDetailsInfo = [];

      const index = i > 0 ? i+1 : "";
      const labelIndex = index!=""?(index-1):index;
      personalDetailsInfo.push({
        "questionText": this.getData("selectPerson" + index, "label", this.step1Name)['label'],
        "questionCd": this.getData("selectPerson" + index, "label", this.step1Name)['questionCd'],
        "answerCd": this.getData("selectPerson" + index, "", this.step1Name).rpaValue,
        "answer": this.getData("selectPerson" + index, "", this.step1Name).name
      });

      let type = []
      if (uiData["selectPerson" + index].id === "1") {
        type = ["nationalIdentity", "dateBirth", "yourselfMailingAddress", "yourselfPostalCode", "yourselfCity", "yourselfCountry"]
      } else if (uiData["selectPerson" + index].id === "2") {
        let someoneElseCheck=this.getData("someoneElseCheck" + index, "", this.step1Name);
        type = ["someoneElseRelationship", "someoneElseFirstName", "someoneElseLastName", "someoneElseNationalIdentity", "someoneElseDateBirth","someoneElseCheck", "someoneElseMailingAddress", "someoneElsePostalCode", "someoneElseCity", "someoneElseCountry"];
        if(uiData["someoneElseRelationship" + index].id === "4"){
          type.splice(1, 0, "someoneElseOthers");
        }

      } 

      if(index =="" && uiData["selectPerson" + index].id === "3" && travelInsurance.length===1){
        type = ["employerName","employerCheck","employerFirstName", "employerLastName", "employerNationalIdentity", "employerDateBirth","employerEmailAddress" ,"employerPhone","claimantCheck","employerMailingAddress","employerPostalCode", "employerCity", "employerCountry"];
      }
      else if(index =="" && uiData["selectPerson" + index].id === "3" && travelInsurance.length>1)
      {
        type = ["employerName"];
      }
      else if(index !=="" && uiData["selectPerson" + index].id === "3"){
        type = ["employerName"];
      }
    let checkEmployer=this.getData("employerCheck" + index, "", this.step1Name);
    let checkClaimant =this.getData("claimantCheck" + index, "", this.step1Name)
      type.forEach((item, i) => {
        if(this.getData(item + index, "label", this.step1Name)['type']==="dropdown" ){
          personalDetailsInfo.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['label'],
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd":  this.getData(item + index, "", this.step1Name).rpaValue,
            "answer":  this.getData(item + index, "", this.step1Name).name?this.getData(item + index, "", this.step1Name).name:this.getData(item + index, "", this.step1Name)
          });
        }
        else if((item ==="yourselfMailingAddress" || item ==="someoneElseMailingAddress" || item ==="employerMailingAddress") && (uiData[item]!==undefined || uiData[index+"val"]!==undefined) && (uiData[item]!==null || uiData[item+index+"val"]!==null)){
         // console.log()
          personalDetailsInfo.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['label'],
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": null,
            "answer": this.dataStoreService.checkAutoComplete(this.getData(item + index+"val", "", this.step1Name)? this.getData(item + index+"val", "", this.step1Name): this.getData(item + index, "", this.step1Name).formatted_address?this.getData(item + index, "", this.step1Name).formatted_address:this.getData(item + index, "", this.step1Name))
          });

         }
        else if(item==="employerEmailAddress" && this.uiData["employerEmailAddress"]!==null && this.uiData["claimantCheck"]!=="yes" && (checkEmployer==="No" || checkEmployer==="Nei"))
          {
          personalDetailsInfo.push({
            "questionText": this.getData("employerEmailAddress" + index, "label", this.step1Name)['label'],
            "questionCd": this.getData("employerEmailAddress" + index, "label", this.step1Name)['questionCd'],
            "answerCd": null,
            "answer": this.getData("employerEmailAddress" + index, "", this.step1Name)
          });
        } 
         else if(item==="employerPhone" && this.uiData["employerPhone"]!==null && this.uiData["claimantCheck"]!=="yes" && (checkEmployer==="No" || checkEmployer==="Nei")) 
         {
          personalDetailsInfo.push({
            "questionText": $localize`:@@phoneNumberNor:Phone Number`,
            "questionCd": "phoneNumber",
            "answerCd": null,
            "answer": this.getData("employerPhone" + index, "", this.step1Name)['phoneCode'].hasOwnProperty('label')? this.getData("employerPhone" + index, "", this.step1Name)['phoneCode']['label']+ "-"  + this.getData("employerPhone" + index, "", this.step1Name)['phoneNumber'] : this.getData("employerPhone" + index, "", this.step1Name)['phoneCode'] + "-"  + this.getData("employerPhone" + index, "", this.step1Name)['phoneNumber']
          });
        } 
       else if(item==="someoneElseCheck"){
          let someoneElseCheck=this.getData("someoneElseCheck" + index, "", this.step1Name);
          if(someoneElseCheck==="No" || someoneElseCheck==="Nei")
          {
            personalDetailsInfo.push({
              "questionText": this.getData("someoneElseEmail" + index, "label", this.step1Name)['label'],
              "questionCd": this.getData("someoneElseEmail" + index, "label", this.step1Name)['questionCd'],
              "answerCd": null,
              "answer": this.getData("someoneElseEmail" + index, "", this.step1Name)
            });

            let someoneElsePhone=this.getData("someoneElsePhone" + index, "", this.step1Name);
            if(someoneElsePhone['phoneNumber']!=="")
            {
              personalDetailsInfo.push(
              {
                "questionText": $localize`:@@phoneNumberNor:Phone Number`,
                "questionCd": "phoneNumber",
                "answerCd": null,
                "answer": this.getData("someoneElsePhone" + index, "", this.step1Name)['phoneCode'].hasOwnProperty('label')? this.getData("someoneElsePhone" + index, "", this.step1Name)['phoneCode']['label']+ "-"  + this.getData("someoneElsePhone" + index, "", this.step1Name)['phoneNumber'] : this.getData("someoneElsePhone" + index, "", this.step1Name)['phoneCode'] + "-"  + this.getData("someoneElsePhone" + index, "", this.step1Name)['phoneNumber']
              });
            }            
          }
          personalDetailsInfo.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['heading'] ,
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": this.getData(item + index, "rpa", this.step1Name),
            "answer": this.getData(item + index, "", this.step1Name)
          });

        }  
        else if((item==="claimantCheck" || item==="employerCheck") && (checkEmployer==="Yes" || checkEmployer==="Ja")){
         // let someoneElseCheck=this.getData("someoneElseCheck" + index, "", this.step1Name);
          if(checkClaimant==="No" || checkClaimant==="Nei")
          {
            personalDetailsInfo.push({
              "questionText": this.getData("employerEmailAddress" + index, "label", this.step1Name)['label'],
              "questionCd": this.getData("employerEmailAddress" + index, "label", this.step1Name)['questionCd'],
              "answerCd": null,
              "answer": this.getData("employerEmailAddress" + index, "", this.step1Name)
            });

            let someoneElsePhone=this.getData("employerPhone" + index, "", this.step1Name);
            if(someoneElsePhone['phoneNumber']!=="")
            {
              personalDetailsInfo.push(
              {
                "questionText": $localize`:@@phoneNumberNor:Phone Number`,
                "questionCd": "phoneNumber",
                "answerCd": null,
                "answer": this.getData("employerPhone", "", this.step1Name)['phoneCode'].hasOwnProperty('label')? this.getData("employerPhone", "", this.step1Name)['phoneCode']['label']+ "-"  + this.getData("employerPhone", "", this.step1Name)['phoneNumber'] : this.getData("employerPhone", "", this.step1Name)['phoneCode'] + "-"  + this.getData("employerPhone", "", this.step1Name)['phoneNumber']
              });
            }            
          }
          //if(item==="claimantCheck")
          personalDetailsInfo.push({
            "questionText": this.getData(item + index, "label", this.step1Name)['heading'] ,
            "questionCd": this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd": this.getData(item + index, "rpa", this.step1Name),
            "answer": this.getData(item + index, "", this.step1Name)
          });

        }  
        
         else {
           if(item!=="employerPhone" && item!=="employerEmailAddress" &&item!=="claimantCheck")
           {
          personalDetailsInfo.push({
            "questionText":((item + index).indexOf('nationalIdentity'+ index)>-1 ||((item + index).indexOf('someoneElseNationalIdentity'+ index)>-1) ||((item + index).indexOf('employerNationalIdentity'+ index)>-1) )? $localize`:@@nationIdent:National Identity Number`: this.getData(item + index, "label", this.step1Name)['label'],
            "questionCd":((item + index).indexOf('nationalIdentity'+ index) >-1||((item + index).indexOf('someoneElseNationalIdentity'+ index)>-1) ||((item + index).indexOf('employerNationalIdentity'+ index)>-1) )? item :this.getData(item + index, "label", this.step1Name)['questionCd'],
            "answerCd":null,
            "answer": ((item + index).indexOf('nationalIdentity'+ index)>-1 ||((item + index).indexOf('someoneElseNationalIdentity'+ index)>-1) ||((item + index).indexOf('employerNationalIdentity'+ index)>-1) )?this.getMaksing(this.getData(item + index, "", this.step1Name)):this.getData(item + index, "", this.step1Name)
          });
        }
        }
      
      });
      
      let headIndex = index != ""? index :1

      SelectPersonArr.push({
        "featureTypeText": "Person #"  + headIndex,
        "featureTypeCd": "person" + headIndex ,
        "questionAnswer":personalDetailsInfo
      }

      )

    })
    return SelectPersonArr
  }

  getPersonalDetailsInfo() {
    let personalDetailsArray = []

    let personalObj = {
      "featureTypeText": $localize`:@@personalDetailsNor:Personal Details`,
      "featureTypeCd": "claimant",
      "questionAnswer": this.getPersonalObj()
    }
    personalDetailsArray.push(personalObj)
    return personalDetailsArray.concat(this.getPersonalContainerVal());
  }

  getUploadDetails(){
    let obj=[]
    if (this.dataStoreService.get("fnol-upload-docs").uiData.customFileUpload){
  let fileList = this.dataStoreService.get("fnol-upload-docs").uiData.customFileUpload.uploads;

  fileList.forEach((element,i) => {
    if(this.language==='en'){
      obj.push(
        {
          "questionText": element.claimBenefitType,
          "questionCd": "document" + (i + 1),
          "answerCd": null,
          "answer": element.name
        }
  
      )
    }
    else{
    obj.push(
      {
        "questionText": element.displayDocType,
        "questionCd": "document" + (i + 1),
        "answerCd": null,
        "answer": element.name
      }

    )
    }
  });

  return obj;
  } else {
    return obj;
  }
}


  getTripDetails() {
    const triDetailsObj=[];
    this.claimTypeDetails.tripDetails=[];
    const tripData = ['tripDate', 'airlineAgency', 'startDate', 'endDate', 'startLocation', 'travelDestination',
    'descriptionRoute', 'travelPurpose'];
  tripData.forEach(fieldName => {
    if(fieldName.indexOf('startLocation')>-1 || fieldName.indexOf('travelPurpose')>-1){
      triDetailsObj.push({
        "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
        "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
        "answerCd":this.getData(fieldName, "", this.step2Name).rpaValue,
        "answer": this.getData(fieldName, "", this.step2Name)?.name
      })
    }else
    if(fieldName.indexOf("travelDestination")>-1){
      triDetailsObj.push({
        "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
        "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
        "answerCd":this.getData(fieldName, "", this.step2Name)?.map(x=>x.id).join(","),
        "answer": this.getData(fieldName, "", this.step2Name)?.map(x=>x.name).join(",")
      })
    }else{
      triDetailsObj.push({
        "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
        "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
        "answerCd":null,
        "answer": this.checkCustom(fieldName,"",this.step2Name)
      })
    }

  });
  this.claimTypeDetails.tripDetails = this.claimTypeDetails.tripDetails.concat(triDetailsObj);
  return triDetailsObj;
  }
  getBaggageDamageDetails(){
    const baggageDamageDetails =[];
    this.claimTypeDetails.dammage=[];
      //  Baggage Damage Details
      const baggageDamageData = ['baggageDamageDetailsDate', 'baggageDamageDetailsTime', 'baggageDamageDetailsCountry', 'locationDetailsBagDam',
      'damageDescriptionCause', 'descriptionBaggage', 'checkedBaggage', 'repairedBaggage','damgeAdditionalBaggage'];
      const baggageDamageDataSuppl = ['carrierBaggageDamageCheck'];

      baggageDamageData.forEach(fieldName => {
        if(fieldName.indexOf('baggageDamageDetailsCountry')>-1 || fieldName.indexOf('locationDetailsBagDam')>-1){
          baggageDamageDetails.push({
            "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
            "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
            "answerCd":this.getData(fieldName, "", this.step2Name).rpaValue,
            "answer": this.getData(fieldName, "", this.step2Name)?.name
          })
        }else{
            baggageDamageDetails.push({
              "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
              "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
              "answerCd":null,
              "answer": this.checkCustom(fieldName,"",this.step2Name)
            })
          }
          if (fieldName ==='checkedBaggage' && this.uiData[fieldName] == 'Yes') {
            baggageDamageDataSuppl.forEach(field => {
              baggageDamageDetails.push({
                "questionText": this.getData(field, "label", this.step2Name)['heading'],
                "questionCd": this.getData(field, "label", this.step2Name)['questionCd'],
                "answerCd":null,
                "answer":  this.checkCustom(field,"",this.step2Name)
              });
              if(field=="carrierBaggageDamageCheck" && this.uiData[field]===null){
                baggageDamageDetails.push({
                  "questionText": this.getData('PIRDamageNumber', "label", this.step2Name)['label'],
                  "questionCd": this.getData('PIRDamageNumber', "label", this.step2Name)['questionCd'],
                  "answerCd":null,
                  "answer":  this.checkCustom('PIRDamageNumber',"",this.step2Name)
                });
              }

            });
            }

           
      });
    // if(this.uiData["claimAmountType"] == "singleWithoutEmployer" || this.uiData["claimAmountType"] == "singleWithEmployer"){
        
        this.selectedData= this.uiData["claimAmountType"];
        baggageDamageDetails.push( {
          "questionText": $localize`:@@ExpensesNo:Expenses`,
          "questionCd": "doYouHaveExpenses",
          "answerCd": null,
          "answer": this.getData("", "", this.step1Name),
          "additionalInfo":this.getClaimAmountBagDamageData() })
      //}  
      this.claimTypeDetails.dammage =this.claimTypeDetails.dammage.concat(baggageDamageDetails);
      return baggageDamageDetails;
  }
  getDammageDelayDetails(){
    const damageDelayObj=[];
    this.claimTypeDetails.baggageDelay=[];
 //  Baggage Delay Details
    const delayLossDetails = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addDelayLossMain").fields;
    delayLossDetails.forEach((label, i) => {
   const index = i > 0 ? i+1 : "";
   const labelIndex = index!=""?(index-1):index;
   damageDelayObj.push({
     "questionText": this.getData("typeDelayLoss" + index, "label", this.step2Name)['label']+labelIndex,
     "questionCd": this.getData("typeDelayLoss" + index, "label", this.step2Name)['questionCd'],
     "answerCd":this.getData("typeDelayLoss" + index, "", this.step2Name)?.rpaValue,
     "answer": this.getData("typeDelayLoss" + index, "", this.step2Name)?.name
   });
   if (this.getData("typeDelayLoss" + index, "", this.step2Name)?.id === '1') {
     const delayed = ['delayedBaggageDate', 'delayedBaggageTime', 'delayedBaggageCountry', 'boxBaggageDate', 'boxBaggageTime', 'boxBaggageCheck', 'numberBags'];

     delayed.forEach(fieldName => {
      if(this.getData(fieldName + index, "label", this.step2Name)['type'] ==="dropdown"){
        damageDelayObj.push({
          "questionText": this.getData(fieldName + index, "label", this.step2Name)['label'] +labelIndex,
          "questionCd": this.getData(fieldName + index, "label", this.step2Name)['questionCd'],
          "answerCd":this.getData(fieldName, "", this.step2Name)?.rpaValue,
          "answer": this.getData(fieldName + index, "", this.step2Name)?.name
        });

      }else{
        damageDelayObj.push({
          "questionText": this.getData(fieldName + index, "label", this.step2Name)['label'] ?
          this.getData(fieldName + index, "label", this.step2Name)['label']+labelIndex :this.getData(fieldName + index, "label", this.step2Name)['heading']+labelIndex,
          "questionCd": this.getData(fieldName + index, "label", this.step2Name)['questionCd'],
          "answerCd":null,
          "answer": this.checkNullDateTime(this.checkCustom(fieldName,index,this.step2Name), fieldName + index)
        });
      }

     })

   } else if (this.getData("typeDelayLoss"+index, "", this.step2Name)?.id === '2') {
     const lostType = ['lostBaggageDate', 'lostBaggageTime', 'lostBaggageCountry', 'lostNumberBags'];
     lostType.forEach(field => {
      if(this.getData(field + index, "label", this.step2Name)['type'] ==="dropdown"){
        damageDelayObj.push({
          "questionText": this.getData(field + index, "label", this.step2Name)['label']+labelIndex,
          "questionCd": this.getData(field + index, "label", this.step2Name)['questionCd'],
          "answerCd":this.getData(field, "", this.step2Name)?.rpaValue,
          "answer": this.getData(field + index, "", this.step2Name)?.name
        });

      }else{
        damageDelayObj.push({
          "questionText": this.getData(field + index, "label", this.step2Name)['label']+labelIndex,
          "questionCd": this.getData(field + index, "label", this.step2Name)['questionCd'],
          "answerCd":null,
          "answer":  this.checkCustom(field,index,this.step2Name)
        });
      }

     })
   } else if (this.getData("typeDelayLoss" + index, "", this.step2Name)?.id === '3') {
     const confirmLost = ['confirmedBaggageDate', 'confirmedBaggageTime', 'confirmedBaggageCountry', 'boxConfirmedDate', 'boxConfirmedTime', 'confirmedBaggageCountryR', 'numberConfirmedBags'];
     confirmLost.forEach(item => {
      if(this.getData(item + index, "label", this.step2Name)['type'] ==="dropdown" ){
        damageDelayObj.push({
          "questionText": this.getData(item + index, "label", this.step2Name)['label']+labelIndex,
          "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
          "answerCd":this.getData(item +index, "", this.step2Name)?.rpaValue,
          "answer": this.getData(item + index, "", this.step2Name)?.name
        });
      }else{
        damageDelayObj.push({
          "questionText": this.getData(item + index, "label", this.step2Name)['label']+labelIndex,
          "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
          "answerCd":null,
          "answer":  this.checkCustom(item,index,this.step2Name)
        });
      }

     })
   }

 })
 
 const baggageDelaySuppl = ['delayBaggage', "delayPaidBaggage",'delaySupplementalAdditionalBaggage']
 baggageDelaySuppl.forEach((fieldName, i) => {
   damageDelayObj.push({
     "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
     "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
     "answerCd":null,
     "answer":  this.checkCustom(fieldName,'',this.step2Name)
   });
   if (fieldName == "delayBaggage" && this.uiData[fieldName] === "Yes") {
     damageDelayObj.push({
       "questionText": this.getData("delayPIRDamageNumber", "label", this.step2Name)['label'],
       "questionCd": this.getData("delayPIRDamageNumber", "label", this.step2Name)['questionCd'],
       "answerCd":null,
       "answer": this.checkCustom('delayPIRDamageNumber','',this.step2Name)
     });
   } else if (fieldName == 'delayPaidBaggage' && this.uiData[fieldName] === "Yes") {
     damageDelayObj.push({
       "questionText": this.getData('compensationDelayAmount', "label", this.step2Name)['label'],
       "questionCd": this.getData('compensationDelayAmount', "label", this.step2Name)['questionCd'],
       "answerCd":null,
       "answer": this.checkCustom('compensationDelayAmount','',this.step2Name)
     });
   }
 });
 this.selectedData= this.uiData["claimAmountType"];
 
 damageDelayObj.push( {
   "questionText": $localize`:@@ExpensesNo:Expenses`,
   "questionCd": "doYouHaveExpenses",
   "answerCd": null,
   "answer": this.getData("", "", this.step1Name),
   "additionalInfo":this.getClaimAmountBagDelayData() })
   
 
    this.claimTypeDetails.baggageDelay=this.claimTypeDetails.baggageDelay.concat(damageDelayObj);
    return damageDelayObj;
  }

  checkNullDateTime(data,name) {
    if ((name.indexOf('boxBaggageDate') || name.indexOf('boxBaggageTime')) && (data == null || !data )) {
    return "";
    }
    else{
      return data;
    }
  }
 
  getTheftDetails(){
    const theftDataObj=[];
    this.claimTypeDetails.theft=[];
    // Baggage Theft Details
    const theftData = ['baggageTheftDetailsDate', 'baggageTheftDetailsTime', 'baggageTheftDetailsCountry', 'theftLocationType', 'locationDetails', 'descriptionCause', 'theftBaggageDate', 'theftBaggageTime'];
    theftData.forEach((fieldName, i) => {
      if(this.getData(fieldName , "label", this.step2Name)['type'] ==="dropdown"){
        theftDataObj.push({
          "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
          "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
          "answerCd":this.getData(fieldName, "", this.step2Name)?.rpaValue,
          "answer": this.getData(fieldName, "", this.step2Name)?.name
        });
      }else{
        theftDataObj.push({
          "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
          "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
          "answerCd":null,
          "answer":  this.checkCustom(fieldName,'',this.step2Name)
        });
      }

    });
    const theftSuppl = [
      "personsBaggage",
      "witnessesBaggage",
      "reportedBaggage",
      "areaBaggage",
      "theftSupplemntalAdditionalBaggage"
    ];
    theftSuppl.forEach((fieldName, i) => {
      theftDataObj.push({
        "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
        "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
        "answerCd":null,
        "answer":this.checkCustom(fieldName,'',this.step2Name)
      });
      if (fieldName == "personsBaggage" && this.uiData[fieldName] === "Yes") {
        theftDataObj.push({
          "questionText": this.getData("descriptionTheft", "label", this.step2Name)['label'],
          "questionCd": this.getData("descriptionTheft", "label", this.step2Name)['questionCd'],
          "answerCd":null,
          "answer":  this.checkCustom('descriptionTheft','',this.step2Name)
        });
      }
      if (fieldName == "witnessesBaggage" && this.uiData[fieldName] == "Yes") {
        const witnessMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addWitnessMain-Yes").fields;
        witnessMain.forEach((field, j) => {
          const witnessLabels = ["nameWitness", "contactInformation"]
          const index = j > 0 ? j+1 : "";
          const labelIndex = index!=""?(index-1):index;
          witnessLabels.forEach(item => {
            theftDataObj.push({
              "questionText": this.getData(item + index, "label", this.step2Name)['label']+labelIndex,
              "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd']+labelIndex,
              "answerCd":null,
              "answer":this.checkCustom(item,index,this.step2Name)
            });
          })

        })
      }
      if (fieldName == "reportedBaggage" && this.uiData[fieldName] == "Yes") {
        theftDataObj.push({
          "questionText": this.getData("crimeReferenceTravel", "label", this.step2Name)['label'],
          "questionCd": this.getData("crimeReferenceTravel", "label", this.step2Name)['questionCd'],
          "answerCd":null,
          "answer": this.checkCustom('crimeReferenceTravel','',this.step2Name)
        });
      }
    })
    this.selectedData= this.uiData["claimAmountType"];
    theftDataObj.push( {
      "questionText": $localize`:@@ExpensesNo:Expenses`,
      "questionCd": "doYouHaveExpenses",
      "answerCd": null,
      "answer": this.getData("", "", this.step1Name),
      "additionalInfo":this.getClaimAmountBagTheftData() })

    this.claimTypeDetails.theft =this.claimTypeDetails.theft.concat(theftDataObj)
    return theftDataObj;
  }
  getRentalDetailsData(){
    const rentalObj=[];
    this.claimTypeDetails.rental=[];

    // car Rental Details
    const carRentalData = [
      "carRentalDetailsDate",
      "carRentalDetailsTime",
      "carRentalDetailsCountry",
      "locationDetailsCarRental",
      "typeOfIncident",
      
    ];
    carRentalData.forEach((fieldName) => {
      if(this.getData(fieldName, "label", this.step2Name)['type'] ==="dropdown"){
        rentalObj.push({
          "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
          "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
          "answerCd":this.getData(fieldName, "", this.step2Name)?.rpaValue,
          "answer": this.getData(fieldName, "", this.step2Name)?.name
        });
      }else{
        rentalObj.push({
          "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
          "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
          "answerCd":null,
          "answer": this.checkCustom(fieldName,'',this.step2Name)
        });
      }
    
     if(fieldName == "typeOfIncident"){
      if (this.getData("typeOfIncident", "", this.step2Name)?.id === '1'){
        const typeOfIncidentData =["incidentCause","impactCause"];
        typeOfIncidentData.forEach((fieldName) => {
          rentalObj.push({
            "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
            "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
            "answerCd":null,
            "answer": this.checkCustom(fieldName,'',this.step2Name)
          });
        })
      }
      else if(this.getData("typeOfIncident", "", this.step2Name)?.id === '4'){
        const typeOfIncidentData =["incidentCauseOther","impactCauseOther"];
         typeOfIncidentData.forEach((fieldName) => {
           rentalObj.push({
             "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
             "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
             "answerCd":null,
             "answer": this.checkCustom(fieldName,'',this.step2Name)
           });
         })
      }
      else {
        const typeOfIncidentData =["incidentCauseTheft"];
        typeOfIncidentData.forEach((fieldName) => {
          rentalObj.push({
            "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
            "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
            "answerCd":null,
            "answer": this.checkCustom(fieldName,'',this.step2Name)
          });
        })
      }
    }
    });

    const rentalSuppl = [
      "carRentalSupplemnetalStartDate",
      "returnDate",
      "companyRental",
      "rent",
     // "paymentType",
      "lossDamageWaiver",
      "carIncident",
     // "incidentReported",
      "descriptionCar"
    ];
    rentalSuppl.forEach((fieldName, i) => {
      // if(fieldName.indexOf('paymentType')>-1){
      //   rentalObj.push({
      //     "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
      //     "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
      //     "answerCd":this.getData(fieldName, "", this.step2Name)?.id,
      //     "answer": this.getData(fieldName, "", this.step2Name)?.name
      //   });
      // }else{
        rentalObj.push({
          "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
          "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
          "answerCd":null,
          "answer": this.checkCustom(fieldName,'',this.step2Name)

        });
       
     // }

      if (fieldName == "rent" && this.uiData[fieldName] === "No") {
        const rentCarNo = ["nameOfPerson", "relationship"];
        rentCarNo.forEach((field, i) => {
          if(field.indexOf('relationship')>-1){
            rentalObj.push({
              "questionText": this.getData(field, "label", this.step2Name)['label'],
              "questionCd": this.getData(field, "label", this.step2Name)['questionCd'],
              "answerCd":this.getData(field, "", this.step2Name)?.rpaValue,
              "answer": this.getData(field, "", this.step2Name)?.name
            })
          }else{
            rentalObj.push({
              "questionText": this.getData(field, "label", this.step2Name)['label'],
              "questionCd": this.getData(field, "label", this.step2Name)['questionCd'],
              "answerCd":null,
              "answer":  this.checkCustom(field,'',this.step2Name)
            })
          }
          if (field == "relationship" && this.getData("relationship", "", this.step2Name)?.id === '8')
            rentalObj.push({
              "questionText": this.getData("descriptionRelationship", "label", this.step2Name)['label'],
              "questionCd": this.getData("descriptionRelationship", "label", this.step2Name)['questionCd'],
              "answerCd":null,
              "answer": this.getData("descriptionRelationship", "", this.step2Name)
            });
        });
      }
      if (fieldName == "paymentType" && this.getData("paymentType", "", this.step2Name)?.id == '1' || fieldName == "paymentType" && this.getData("paymentType", "", this.step2Name)?.id == '2' || fieldName == "paymentType" && this.getData("paymentType", "", this.step2Name)?.id == '3') {
        const FieldVal = this.getData("paymentType", "", this.step2Name);
        const PaymentLabels = FieldVal?.id === '1' ? ["creditCardType", "creditCard4Type", "creditCardCheck"] : FieldVal?.id === '2' ? [ "debitCardCheck"] : ["neitherCheck"];
        PaymentLabels.forEach(item => {
          rentalObj.push({
            "questionText": this.getData(item, "label", this.step2Name)['label'],
            "questionCd": this.getData(item, "label", this.step2Name)['questionCd'],
            "answerCd":null,
            "answer": this.checkCustom(item,'',this.step2Name)
          });
        })

      }
      if (fieldName == "carIncident" && this.uiData[fieldName] === "No") {
        const carIncidentNo = ["nameOfPersonCar", "relationshipCar",];
        carIncidentNo.forEach((data, i) => {
          if(data.indexOf('relationshipCar')>-1){
            rentalObj.push({
              "questionText": this.getData(data, "label", this.step2Name)['label'],
              "questionCd": this.getData(data, "label", this.step2Name)['questionCd'],
              "answerCd": this.getData(data, "", this.step2Name)?.rpaValue,
              "answer": this.getData(data, "", this.step2Name)?.name
            })
          }else{
            rentalObj.push({
              "questionText": this.getData(data, "label", this.step2Name)['label'],
              "questionCd": this.getData(data, "label", this.step2Name)['questionCd'],
              "answerCd":null,
              "answer":  this.checkCustom(data,'',this.step2Name)
            })
          }

        });
        if (this.getData("relationshipCar", "", this.step2Name)?.id === '8')
          rentalObj.push({
            "questionText": this.getData("descriptionCarRelationship", "label", this.step2Name)['label'],
            "questionCd": this.getData("descriptionCarRelationship", "label", this.step2Name)['questionCd'],
            "answerCd":null,
            "answer": this.getData("descriptionCarRelationship", "", this.step2Name)
          });
      }
    });
    this.selectedData= this.uiData["claimAmountType"];
    rentalObj.push( {
      "questionText": $localize`:@@ExpensesNo:Expenses`,
      "questionCd": "doYouHaveExpenses",
      "answerCd": null,
      "answer": this.getData("", "", this.step1Name),
      "additionalInfo":this.getClaimAmountCarRentalData() })
      
    this.claimTypeDetails.rental=this.claimTypeDetails.rental.concat(rentalObj);
    return rentalObj;
  }

  getTravelMedicalData(){
    const travelMedicalObj=[];
    this.claimTypeDetails.medical=[];

     // Travel medical Details
     travelMedicalObj.push({
      "questionText": $localize`:@@areYouclaim:Are you claiming expenses for a travel accident or illness?`,
      "questionCd": this.getData("claimingExpenses", "label", this.step2Name)['questionCd'],
      "answerCd":null,
      "answer": this.getData("claimingExpenses", "", this.step2Name)
    });
    if (this.uiData['claimingExpenses'] === "Travel Accident") {
      const accidentData = ['travelMedicalDetailsDate', 'travelMedicalDetailsTime', 'travelMedicalDetailsCountry','locationTravelAccident', 'attentionDate', 'attentionTime', 'medicalFacility'];
      accidentData.forEach((expense) => {
        travelMedicalObj.push({
          "questionText": this.getData(expense, "label", this.step2Name)['label'],
          "questionCd": this.getData(expense, "label", this.step2Name)['questionCd'],
          "answerCd":(expense.indexOf('travelMedicalDetailsCountry')>-1 || expense.indexOf('locationTravelAccident')>-1)? this.getData(expense, "", this.step2Name).rpaValue: this.checkCustom(expense,'',this.step2Name),
          "answer":(expense.indexOf('travelMedicalDetailsCountry')>-1 || expense.indexOf('locationTravelAccident')>-1)? this.getData(expense, "", this.step2Name)?.name: this.checkCustom(expense,'',this.step2Name)
        });
      });
    } else if (this.uiData['claimingExpenses'] === "Travel Illness") {
      const illnessData = ['travelIllnessDetailsDate', 'travelIllnessDetailsTime', 'travelIllnessDetailsCountry','locationDetailsIllness', 'illnessAttentionDate', 'illnessAttentionTime', 'illnessMedicalFacility'];
      illnessData.forEach((travelIllnesExpense) => {
        travelMedicalObj.push({
          "questionText": this.getData(travelIllnesExpense, "label", this.step2Name)['label'],
          "questionCd": this.getData(travelIllnesExpense, "label", this.step2Name)['questionCd'],
          "answerCd":(travelIllnesExpense.indexOf('travelIllnessDetailsCountry')>-1 || travelIllnesExpense.indexOf('locationDetailsIllness')>-1)? this.getData(travelIllnesExpense, "", this.step2Name).rpaValue:null,
          "answer": (travelIllnesExpense.indexOf('travelIllnessDetailsCountry')>-1 || travelIllnesExpense.indexOf('locationDetailsIllness')>-1)? this.getData(travelIllnesExpense, "", this.step2Name)?.name:this.checkCustom(travelIllnesExpense,'',this.step2Name)
        });
      });
    } else if (this.uiData['claimingExpenses'] === "Other") {
      const otherData = ["otherDate","otherCountry","locationDetailsOther","otherClaimDesc"];
      otherData.forEach((other) => {
      travelMedicalObj.push({
        "questionText": this.getData(other, "label", this.step2Name)['label'],
        "questionCd": this.getData(other, "label", this.step2Name)['questionCd'],
        "answerCd": (other.indexOf('otherCountry')>-1 || other.indexOf('locationDetailsOther')>-1)?this.getData(other, "", this.step2Name).rpaValue:null,
        "answer": (other.indexOf('otherCountry')>-1 || other.indexOf('locationDetailsOther')>-1)?this.getData(other, "", this.step2Name)?.name:this.checkCustom(other,'',this.step2Name)
      });
    });
    }

    travelMedicalObj.push({
      "questionText": this.getData("curtailed", "label", this.step2Name)['label'],
      "questionCd": this.getData("curtailed", "label", this.step2Name)['questionCd'],
      "answerCd":null,
      "answer": this.getData("curtailed", "", this.step2Name)
    });

    if (this.uiData['curtailed'] === "Yes") {
      const medicalCurtailData = ['dateCurtailed','paidCost', 'descriptionCurtailment'];
      medicalCurtailData.forEach((label, i) => {
        travelMedicalObj.push({
          "questionText": this.getData(label, "label", this.step2Name)['label'],
          "questionCd": this.getData(label, "label", this.step2Name)['questionCd'],
          "answerCd":null,
          "answer":this.checkCustom(label,'',this.step2Name)
        });
      });
    }
    if (this.uiData['claimingExpenses'] === "Travel Accident") {
      const accidentSuppl = ['descriptionAccident', 'diagnosis', 'bedRest', 'infoAccident'];
      accidentSuppl.forEach((itemName, i) => {
        travelMedicalObj.push({
          "questionText": this.getData(itemName, "label", this.step2Name)['label'],
          "questionCd": this.getData(itemName, "label", this.step2Name)['questionCd'],
          "answerCd":(itemName.indexOf('diagnosis')>-1)?this.getData(itemName, "", this.step2Name)?.map(x=>x.rpaValue).join(","):null,
          "answer": (itemName.indexOf('diagnosis')>-1)?this.getData(itemName, "", this.step2Name)?.map(x=>x.name).join(","): this.checkCustom(itemName,'',this.step2Name)
        });
        if (itemName == "bedRest" && this.uiData[itemName] == "Yes") {
          const bedRestYes = ['bedRestStartDate', 'bedRestStartTime', 'bedRestEndDate', 'bedRestEndTime'];
          bedRestYes.forEach((field, i) => {
            travelMedicalObj.push({
              "questionText": this.getData(field, "label", this.step2Name)['label'],
              "questionCd": this.getData(field, "label", this.step2Name)['questionCd'],
              "answerCd":null,
              "answer": this.getData(field, "", this.step2Name)
            });
          });
        }
      });
    } else if (this.uiData['claimingExpenses'] === "Travel Illness") {
      const illnessSuppl = [
        "descriptionSymptoms",
        "diagnosisSymptoms",
        "illnessBedRest",
        "illnesses",
        "illnessInfoAccident"
      ];
      illnessSuppl.forEach((fieldName, i) => {
        travelMedicalObj.push({
          "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
          "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
          "answerCd":(fieldName.indexOf('diagnosisSymptoms')>-1)?this.getData(fieldName, "", this.step2Name)?.map(x=>x.rpaValue).join(","):null,
          "answer": (fieldName.indexOf('diagnosisSymptoms')>-1)?this.getData(fieldName, "", this.step2Name)?.map(x=>x.name).join(","):this.checkCustom(fieldName,'',this.step2Name)
        });
        if (fieldName == "illnesses" && this.uiData[fieldName] == "Yes") {
          const illnessesYes = ["illnessDescription"];
          illnessesYes.forEach((field, i) => {
            travelMedicalObj.push({
              "questionText": this.getData(field, "label", this.step2Name)['label'],
              "questionCd": this.getData(field, "label", this.step2Name)['questionCd'],
              "answerCd":null,
              "answer": this.checkCustom(field,'',this.step2Name)
            });
          });
        }
        if (fieldName == "illnessBedRest" && this.uiData[fieldName] == "Yes") {
          const illnessBedRestYes = ["illnessBedRestStartDate", "illnessBedRestStartTime", "illnessBedRestEndDate", "illnessBedRestEndTime",];
          illnessBedRestYes.forEach((field, i) => {
            travelMedicalObj.push({
              "questionText": this.getData(field, "label", this.step2Name)['label'],
              "questionCd": this.getData(field, "label", this.step2Name)['questionCd'],
              "answerCd":null,
              "answer":   this.checkCustom(field,'',this.step2Name)
            });
          });
        }
      });
    }

    travelMedicalObj.push({
      "questionText": $localize`:@@ExpensesNo:Expenses`,
  "questionTypeCd": "doYouHaveExpenses",
  "answerCd": null,
  "answer": "",
      "additionalInfo": this.getClaimAmountTravelMedicalData()
    })

    this.claimTypeDetails.medical=this.claimTypeDetails.medical.concat(travelMedicalObj);
    return travelMedicalObj;
  }
  getTripCancelData(){
    const tripCancelObj=[];
    this.claimTypeDetails.cancel=[];

     // Trip Cancellation Details
     const tripCancel = ["cancelType"];
     tripCancel.forEach((fieldName, i) => {
       tripCancelObj.push({
         "questionText": $localize`:@@tripCancel:What was the reason for the trip cancellation?`,
         "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
         "answerCd":null,
         "answer": this.getData(fieldName, "", this.step2Name)
       });
       if (this.uiData[fieldName] == "Own Accident or Illness") {
         const ownAccidentData = ['ownDate', 'typeAccident', 'ownTripCancelDetailsDate', 'circumstances', 'ownAdditionalCancel'];
         ownAccidentData.forEach((field, i) => {
           tripCancelObj.push({
             "questionText": this.getData(field, "label", this.step2Name)['label'],
             "questionCd": this.getData(field, "label", this.step2Name)['questionCd'],
             "answerCd":(field.indexOf('typeAccident')>-1)?this.getData(field, "", this.step2Name)?.rpaValue:null,
             "answer": (field.indexOf('typeAccident')>-1)?this.getData(field, "", this.step2Name)?.name:  this.checkCustom(field,'',this.step2Name)
           });
           if (field == "typeAccident" && this.getData(field, "", this.step2Name)?.id === '3') {
             tripCancelObj.push({
               "questionText": this.getData("tripCanceladditionalBaggage", "label", this.step2Name)['label'],
               "questionCd": this.getData("tripCanceladditionalBaggage", "label", this.step2Name)['questionCd'],
               "answerCd":null,
               "answer": this.getData("tripCanceladditionalBaggage", "", this.step2Name)
             });
           }
         });
       } else if (this.uiData[fieldName] == "Close Family/Travel Companion's Accident or Illness") {
         const kinsData = ['nextRelationship', 'kinsOwnDate', 'kinsTypeAccident', 'kinsTripCancelDetailsDate', 'kinsCircumstances', 'kinsAdditionalCancel'];
         kinsData.forEach((field, i) => {
           tripCancelObj.push({
             "questionText": this.getData(field, "label", this.step2Name)['label'],
             "questionCd": this.getData(field, "label", this.step2Name)['questionCd'],
             "answerCd":(field.indexOf('nextRelationship')>-1 || field.indexOf('kinsTypeAccident')>-1)?this.getData(field, "", this.step2Name)?.rpaValue:null,
             "answer": (field.indexOf('nextRelationship')>-1 || field.indexOf('kinsTypeAccident')>-1)?this.getData(field, "", this.step2Name)?.name: this.checkCustom(field,'',this.step2Name)
           });
           if (field == "nextRelationship" && this.getData("nextRelationship", "", this.step2Name)?.id === '8') {
             tripCancelObj.push({
               "questionText": this.getData("nextDescriptionRelationship", "label", this.step2Name)['label'],
               "questionCd": this.getData("nextDescriptionRelationship", "label", this.step2Name)['questionCd'],
               "answerCd":null,
               "answer": this.getData("nextDescriptionRelationship", "", this.step2Name)
             });
           }
           if (field == "kinsTypeAccident" && this.getData("kinsTypeAccident", "", this.step2Name)?.id === '3') {
             tripCancelObj.push({
               "questionText": this.getData("KinsAdditionalBaggage", "label", this.step2Name)['label'],
               "questionCd": this.getData("KinsAdditionalBaggage", "label", this.step2Name)['questionCd'],
               "answerCd":null,
               "answer": this.getData("KinsAdditionalBaggage", "", this.step2Name)
             });
           }
         });
        } else if (this.uiData[fieldName] == "Other Cause") {
          const otherData = ['causeDetails', 'otherTripCancelDetailsDate', 'otherAdditionalCancel'];
          otherData.forEach((field, i) => {
            tripCancelObj.push({
              "questionText": this.getData(field, "label", this.step2Name)['label'],
              "questionCd": this.getData(field, "label", this.step2Name)['questionCd'],
              "answerCd":null,
              "answer": this.checkCustom(field, "", this.step2Name)
            });
          })
        }  
     });
     this.selectedData= this.uiData["claimAmountType"];
     tripCancelObj.push( {
       "questionText": $localize`:@@ExpensesNo:Expenses`,
       "questionCd": "doYouHaveExpenses",
       "answerCd": null,
       "answer": this.getData("", "", this.step1Name),
       "additionalInfo":this.getClaimAmountTripCancelData() })

    this.claimTypeDetails.cancel=this.claimTypeDetails.cancel.concat(tripCancelObj);
    return tripCancelObj;
  }
  getTripDelayData(){
    const tripDelayObj = [];
    this.claimTypeDetails.tripDelay=[];

    // TripDelay Details
    const tripDelayData = ['tripDelayDetailsCountry', 'typeTransportation', 'transportationCheck', 'reasonDelay', 'tripDelayAdditionalBaggage']
    let transportationCheck = this.dataStoreService.get(this.step2Name).uiData["transportationCheck"];
   
    tripDelayData.forEach((fieldName, i) => {
     if(fieldName.indexOf('tripDelayDetailsCountry')>-1){
      tripDelayObj.push({
        "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
        "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
        "answerCd":(fieldName).toLowerCase().includes('country')?(this.getData(fieldName, "", this.step2Name).rpaValue):null,
        "answer": this.getData(fieldName, "", this.step2Name)?.name
      });
    }
     else if (fieldName == "typeTransportation" && fieldName.indexOf('typeTransportation')>-1){
        tripDelayObj.push({
          "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
          "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
          "answerCd":this.getData(fieldName, "", this.step2Name)?.rpaValue,
          "answer": this.getData(fieldName, "", this.step2Name).name
        });
        if (fieldName == "typeTransportation" && this.getData(fieldName, "", this.step2Name)?.id == '7') {
            tripDelayObj.push({
              "questionText": this.getData('descriptionType', "label", this.step2Name)['label'],
              "questionCd": this.getData('descriptionType', "label", this.step2Name)['questionCd'],
              "answerCd":null,
              "answer": this.getData('descriptionType', "", this.step2Name)
            });
          }
          
      }
     else if (fieldName == "reasonDelay" && fieldName.indexOf('reasonDelay')>-1){
       if(transportationCheck==null)
       {
        tripDelayObj.push({
          "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
          "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
          "answerCd":this.getData(fieldName, "", this.step2Name)?.rpaValue,
          "answer": this.getData(fieldName, "", this.step2Name)?.name
        });
      }
      }else{
      tripDelayObj.push({
        "questionText": this.getData(fieldName, "label", this.step2Name)['label'] !== undefined
        ?this.getData(fieldName, "label", this.step2Name)['label']:this.getData(fieldName, "label", this.step2Name)['heading'] ,
        "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
        "answerCd":null,
        "answer": this.getData(fieldName, "", this.step2Name)
      });
     }
     
    });
    const tripDealySuppl = [
      "scheduledDepartureDate",
      "scheduledDepartureTime",
      "scheduledArrivalDate",
      "scheduledArrivalTime",
      "actualDepartureDate",
      "actualDepartureTime",
      "actualArrivalDate",
      "actualArrivalTime",
      "departureDelay",
      "airlineDelay",
      "additionalDelay"
    ]
    tripDealySuppl.forEach((fieldName, i) => {
      tripDelayObj.push({
        "questionText": this.getData(fieldName, "label", this.step2Name)['label'],
        "questionCd": this.getData(fieldName, "label", this.step2Name)['questionCd'],
        "answerCd":null,
        "answer":  this.checkCustom(fieldName,'',this.step2Name)
      });
      if (fieldName == "departureDelay" && this.uiData[fieldName] == "Yes") {
        const departureDelayYes = ["departureDate", "departureTime"];

        departureDelayYes.forEach((field, i) => {
          tripDelayObj.push({
            "questionText": this.getData(field, "label", this.step2Name)['label'],
            "questionCd": this.getData(field, "label", this.step2Name)['questionCd'],
            "answerCd":null,
            "answer":  this.checkCustom(field,'',this.step2Name)
          });
        });
      }else if (fieldName == "airlineDelay" && this.uiData[fieldName] == "Yes") {
        const airlineDelayYes = ["compensationAmount", "compensationFor"];
        airlineDelayYes.forEach((item, i) => {
          tripDelayObj.push({
            "questionText": this.getData(item, "label", this.step2Name)['label'],
            "questionCd": this.getData(item, "label", this.step2Name)['questionCd'],
            "answerCd":null,
            "answer":   this.checkCustom(item,'',this.step2Name)
          });
        });
      }
    });

    tripDelayObj.push({
      "questionText": $localize`:@@ExpensesNo:Expenses`,
      "questionCd": "doYouHaveExpenses",
      "answerCd": null,
      "answer": "",
      "additionalInfo": this.getTripDelayDetailsClaimAmountData()
    })

    this.claimTypeDetails.tripDelay=this.claimTypeDetails.tripDelay.concat(tripDelayObj);
    return tripDelayObj;

  }

  getTripDelayDetailsClaimAmountData() {
    const claimAmountObj=[];
    this.claimTypeDetails.claimAmount =[];
      if (this.uiData["claimAmountType"] == "singleWithoutEmployer") {
        const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountTripDelayNoEmp").fields;
        singleClaimAmountDataMain.forEach((data, i) => {
          let arr1 = []
          const index = i > 0 ? i + 1 : "";
          const labelIndex = index != "" ? (index - 1) : index;
          const matches = data.name.match(/(\d+)/);
          const index1 = matches != null && matches[0] !='1'? matches[0] :"";
          const nonBagNoEMp = ["typeOfExpenseWithoutDropdownTrip","expenseDescriptionTripDelayNoEmp","purchaseDateTripDelayWithoutEmp", "purchasePriceTripDelayNoEmp"];
          nonBagNoEMp.forEach((item, i) => {
            if(this.uiData[item+index]){
              if(item+index === 'purchasePriceTripDelayNoEmp'+index){
                if(this.uiData[item+index]?.phoneNumber){
                  arr1.push({
                    "questionText": this.getData(item + index1, "label", this.step2Name)['label'] ,
                    "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
                    "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
                    "answer":  this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
                  });
                }
              }
              else{
                arr1.push({
                  "questionText": this.getData(item + index1, "label", this.step2Name)['label'] ,
                  "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
                  "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
                  "answer":  this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
                });
              }              
            }
          });
          let headIndex = index != ""? index :1

          claimAmountObj.push({
            "featureTypeText":$localize`:@@expense:Expense #` + headIndex ,
            "featureTypeCd": "expenseNo" + headIndex ,
            "questionAnswer": arr1

          })
        })

      }
      else if (this.uiData["claimAmountType"] == "singleWithEmployer") {
        const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountTripDelayEmp").fields;
        singleClaimAmountDataMain.forEach((data, i) => {
          let arr1 = []
          const index = i > 0 ? i+1 : "";
          const labelIndex = index!=""?(index-1):index;
          const matches = data.name.match(/(\d+)/);
          const index1 = matches != null && matches[0] !='1'? matches[0] :"";
          const nonBagEMp = ['typeOfExpenseDropdownWithTrip','expenseDescriptionClaimLabelTripDelayEmp','empPrivateTripDelayEmp','purchasePriceTripDelayEmp', 'purchaseDateTripDelayEmp'];
          nonBagEMp.forEach((item, i) => {
            if(this.uiData[item+index]){
              if(item+index === 'purchasePriceTripDelayEmp'+index || item+index === 'empPrivateTripDelayEmp'+index){
                if(this.uiData[item+index]?.phoneNumber || this.uiData[item+index]?.id){
                  arr1.push({
                    "questionText": this.getData(item + index1, "label", this.step2Name)['label'] ,
                    "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
                    "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
                    "answer":  this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
                  });
                }
              }
              else{
                arr1.push({
                  "questionText": this.getData(item + index1, "label", this.step2Name)['label'] ,
                  "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
                  "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
                  "answer":  this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
                });
              }              
            }
          });
          let headIndex = index != ""? index :1

          claimAmountObj.push({
            "featureTypeText": $localize`:@@expense:Expense #` + headIndex ,
            "featureTypeCd": "expenseNo" + headIndex,
            "questionAnswer": arr1
          })
        })
      }
    
    this.claimTypeDetails.claimAmount= this.claimTypeDetails.claimAmount.concat(claimAmountObj);
    return claimAmountObj;
  }

  getClaimAmountBagDamageData(){
    const claimAmountObj=[];
    this.claimTypeDetails.claimAmount =[];
    
    if (this.selectedData == "singleWithoutEmployer") {
      const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountBaggageDamage").fields;
      singleClaimAmountDataMain.forEach((data, i) => {
        let arr1 = []
        const index = i > 0 ? i + 1 : "";
        const labelIndex = index != "" ? (index - 1) : index;
        const matches = data.name.match(/(\d+)/);
          const index1 = matches != null && matches[0] !='1'? matches[0] :"";
        const nonBagNoEMp = ["expenseDescriptionNoBagNoEmp","purchaseDateBagWithoutEmp", "amountNoBagNoEmp"];
        nonBagNoEMp.forEach((item, i) => {
          arr1.push({
            "questionText": this.getData(item + index1, "label", this.step2Name)['label'] ,
            "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
            "answerCd": null,
            "answer":  this.checkCustom(item,index1,this.step2Name)
          });
        });
        let headIndex = index != ""? index :1

        claimAmountObj.push({
          "featureTypeText":$localize`:@@expense:Expense #` + headIndex ,
          "featureTypeCd": "expenseNo" + headIndex ,
          "questionAnswer": arr1

        })
      })

    }
    else if (this.selectedData == "singleWithEmployer") {
      const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountBagDamageEMP").fields;
      singleClaimAmountDataMain.forEach((data, i) => {
        let arr1 = []
        const index = i > 0 ? i+1 : "";
        const labelIndex = index!=""?(index-1):index;
        const matches = data.name.match(/(\d+)/);
          const index1 = matches != null && matches[0] !='1'? matches[0] :"";
        const nonBagEMp = ['expenseDescriptionClaimBagEmp','purchaseDateBagEmp', 'purchasePriceBagEmp', 'empPrivateBagEmp'];
        nonBagEMp.forEach((item, i) => {
          arr1.push({
            "questionText": this.getData(item + index1, "label", this.step2Name)['label'],
            "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
            "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
            "answer": this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
          });
        });
        let headIndex = index != ""? index :1

        claimAmountObj.push({
          "featureTypeText": $localize`:@@expense:Expense #` + headIndex ,
          "featureTypeCd": "expenseNo" + headIndex,
          "questionAnswer": arr1
        })
      })
    }
    this.claimTypeDetails.claimAmount= this.claimTypeDetails.claimAmount.concat(claimAmountObj);
    return claimAmountObj;
  }
  getClaimAmountBagTheftData(){
    const claimAmountObj=[];
    this.claimTypeDetails.claimAmount =[];
    
    if (this.selectedData == "singleWithoutEmployer") {

      const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountBaggageTheft").fields;
      singleClaimAmountDataMain.forEach((data, i) => {
        let arr1 = []
        const index = i > 0 ? i + 1 : "";
        const labelIndex = index != "" ? (index - 1) : index;
        const matches = data.name.match(/(\d+)/);
          const index1 = matches != null && matches[0] !='1'? matches[0] :"";
        const nonBagTheftNoEMp = ["expenseDesClaimBagTheftNoEmp","purchaseDateBagTheftNoEmp", "amountBagTheftNoEmp"];
        nonBagTheftNoEMp.forEach((item, i) => {
          arr1.push({
            "questionText": this.getData(item + index1, "label", this.step2Name)['label'] ,
            "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
            "answerCd": null,
            "answer":  this.checkCustom(item,index1,this.step2Name)
          });
        });
        let headIndex = index != ""? index :1

        claimAmountObj.push({
          "featureTypeText":$localize`:@@expense:Expense #` + headIndex ,
          "featureTypeCd": "expenseNo" + headIndex ,
          "questionAnswer": arr1

        })
      })

    }
    else if (this.selectedData == "singleWithEmployer") {
      const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountBagTheftEMP").fields;
      singleClaimAmountDataMain.forEach((data, i) => {
        let arr1 = []
        const index = i > 0 ? i+1 : "";
        const labelIndex = index!=""?(index-1):index;
        const matches = data.name.match(/(\d+)/);
          const index1 = matches != null && matches[0] !='1'? matches[0] :"";
        const nonBagTheftEMp = ['expenseDesClaimBagTheftEmp','purchaseDateBagTheftEmp', 'purchasePriceBagTheftEmp', 'empPrivateBagTheftEmp'];
        nonBagTheftEMp.forEach((item, i) => {
          arr1.push({
            "questionText": this.getData(item + index1, "label", this.step2Name)['label'],
            "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
            "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
            "answer": this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
          });
        });
        let headIndex = index != ""? index :1

        claimAmountObj.push({
          "featureTypeText": $localize`:@@expense:Expense #` + headIndex ,
          "featureTypeCd": "expenseNo" + headIndex,
          "questionAnswer": arr1
        })
      })
    }
    this.claimTypeDetails.claimAmount= this.claimTypeDetails.claimAmount.concat(claimAmountObj);
    return claimAmountObj;
  }
  getClaimAmountCarRentalData(){
    const claimAmountObj=[];
    this.claimTypeDetails.claimAmount =[];
    
    if (this.selectedData == "singleWithoutEmployer") {

      const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountCarRental").fields;
      singleClaimAmountDataMain.forEach((data, i) => {
        let arr1 = []
        const index = i > 0 ? i + 1 : "";
        const labelIndex = index != "" ? (index - 1) : index;
        const matches = data.name.match(/(\d+)/);
          const index1 = matches != null && matches[0] !='1'? matches[0] :"";
        const nonCarRentalNoEMp = ["typeOfExpenseCarRentalNoEMP","expenseDesCarRentalNoEmp","purchaseDateCarRentalNoEmp", "amountCarRentalNoEmp"];
        nonCarRentalNoEMp.forEach((item, i) => {
          arr1.push({
            "questionText": this.getData(item + index1, "label", this.step2Name)['label'] ,
            "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
            "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
            "answer": this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
          });
        });
        let headIndex = index != ""? index :1

        claimAmountObj.push({
          "featureTypeText":$localize`:@@expense:Expense #` + headIndex ,
          "featureTypeCd": "expenseNo" + headIndex ,
          "questionAnswer": arr1

        })
      })

    }
    else if (this.selectedData == "singleWithEmployer") {
      const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountCarRentalEMP").fields;
      singleClaimAmountDataMain.forEach((data, i) => {
        let arr1 = []
        const index = i > 0 ? i+1 : "";
        const labelIndex = index!=""?(index-1):index;
        const matches = data.name.match(/(\d+)/);
          const index1 = matches != null && matches[0] !='1'? matches[0] :"";
        const nonCarRentalEMp = ['typeOfExpenseCarRentalEMP','expenseDesClaimCarRentalEmp','empPrivateCarRentalEmp','purchaseDateCarRentalEmp', 'purchasePriceCarRentalEmp'];
        nonCarRentalEMp.forEach((item, i) => {
          arr1.push({
            "questionText": this.getData(item + index1, "label", this.step2Name)['label'],
            "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
            "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
            "answer": this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
          });
        });
        let headIndex = index != ""? index :1

        claimAmountObj.push({
          "featureTypeText": $localize`:@@expense:Expense #` + headIndex ,
          "featureTypeCd": "expenseNo" + headIndex,
          "questionAnswer": arr1
        })
      })
    }
    this.claimTypeDetails.claimAmount= this.claimTypeDetails.claimAmount.concat(claimAmountObj);
    return claimAmountObj;
  }
  getClaimAmountBagDelayData(){
    let delayLossData= this.getData("typeDelayLoss", "", this.step2Name).id
    console.log(delayLossData,"loss delay");
    const claimAmountObj=[];
    this.claimTypeDetails.claimAmount =[];
    
    if (this.selectedData == "singleWithoutEmployer") {
      
      if(delayLossData =='1'){
        const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountBaggageDelay").fields;
        singleClaimAmountDataMain.forEach((data, i) => {
        let arr1 = []
        const index = i > 0 ? i + 1 : "";
        const labelIndex = index != "" ? (index - 1) : index;
        const matches = data.name.match(/(\d+)/);
          const index1 = matches != null && matches[0] !='1'? matches[0] :"";
           
        const nonBagDelayNoEMp = ["typeOfExpenseBagDelayNoEMP","expensesForBagDelayNoEMP","expenseDesClaimBagDelayNoEmp", "purchaseDateBagDelayNoEmp", "amountBagDelayNoEmp"];
        nonBagDelayNoEMp.forEach((item, i) => {
          arr1.push({
            "questionText": this.getData(item + index1, "label", this.step2Name)['label'] ,
            "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
            "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
            "answer": this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
          });
        });
        let headIndex = index != ""? index :1

        claimAmountObj.push({
          "featureTypeText":$localize`:@@expense:Expense #` + headIndex ,
          "featureTypeCd": "expenseNo" + headIndex ,
          "questionAnswer": arr1

        })
      })
    }
      
       if(delayLossData =='2'){
        const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountBaggageDelayLost").fields;
        singleClaimAmountDataMain.forEach((data, i) => {
        let arr1 = []
        const index = i > 0 ? i + 1 : "";
        const labelIndex = index != "" ? (index - 1) : index;
        const matches = data.name.match(/(\d+)/);
          const index1 = matches != null && matches[0] !='1'? matches[0] :"";
        const nonBagDelayNoEMpLost = ["itemDesClaimBagDelayLostNoEmp","expensesForBagDelayLostNoEMP","purchaseDateBagDelayLostNoEmp", "amountBagDelayLostNoEmp"];
        nonBagDelayNoEMpLost.forEach((item, i) => {
          arr1.push({
            "questionText": this.getData(item + index1, "label", this.step2Name)['label'] ,
            "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
            "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
            "answer": this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
          });
        });
        let headIndex = index != ""? index :1

        claimAmountObj.push({
          "featureTypeText":$localize`:@@expense:Expense #` + headIndex ,
          "featureTypeCd": "expenseNo" + headIndex ,
          "questionAnswer": arr1

        })
        })
       }
      

       let confLost = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountBaggageDelayConfLost").name;
       //let mainIndex = 0
       if(delayLossData =='3' && confLost== "addClaimAmountBaggageDelayConfLost"){

        const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountBaggageDelayConfLost").fields;
         singleClaimAmountDataMain.forEach((data, i) => {
          let arr1 = []
          //mainIndex+=1;
          const index = i > 0 ? i + 1 : "";
          const labelIndex = index != "" ? (index - 1) : index;
          const matches = data.name.match(/(\d+)/);
          const index1 = matches != null && matches[0] !='1'? matches[0] :"";
        const nonBagDelayNoConfBagEMP = ["typeOfExpBagDelayConfLostNoEMP","expensesForBagDelayConfLostNoEMP","expenseDesBagDelayConfLostNoEmp", "purchaseDateBagDelayConfLostNoEmp","amountBagDelayConfLostNoEmp"];
        nonBagDelayNoConfBagEMP.forEach((item, i) => {
          arr1.push({
            "questionText": this.getData(item + index1, "label", this.step2Name)['label'] ,
            "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
            "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
            "answer": this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
          });
        });
        
       
        let headIndex = index != ""? index :1
        claimAmountObj.push({
          "featureTypeText":$localize`:@@expense:Expense #` + headIndex ,
          "featureTypeCd": "expenseNo" + headIndex ,
          "questionAnswer": arr1

        })
      })
      }
      let confirmedLost = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountBagDelayConfLost").name;
     
      if(delayLossData =='3' && confirmedLost=="addClaimAmountBagDelayConfLost"){
        
        const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountBagDelayConfLost").fields;
        singleClaimAmountDataMain.forEach((data, i) => {
         let arr1 = []
         //mainIndex+=1;
        const index = i > 0 ? i + 1 : "";
        const labelIndex = index != "" ? (index - 1) : index;
        const matches = data.name.match(/(\d+)/);
          const index1 = matches != null && matches[0] !='1'? matches[0] :""; 
        const nonBagDelayNoConfLostEMP = ["ItemDescBagDelayConfLostNoEmp","expForBagDelayConfLostNoEMP","purDateBagDelayConfLostNoEmp","amtBagDelayConfLostNoEmp"]
        nonBagDelayNoConfLostEMP.forEach((item, i) => {
          arr1.push({
            "questionText": this.getData(item + index1, "label", this.step2Name)['label'] ,
            "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
            "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
            "answer": this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
          });
        });
       
        let headIndex = index != ""? index :1
        claimAmountObj.push({
          "featureTypeText":$localize`:@@expense:Expense #` + headIndex,
          "featureTypeCd": "expenseNo" + headIndex,
          "questionAnswer": arr1

        })
        })
       }
      
    }
    else if (this.selectedData == "singleWithEmployer") {
      
        if(delayLossData =='1'){
          const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountBaggageDelayEMP").fields;
          singleClaimAmountDataMain.forEach((data, i) => {
            let arr1 = []
            const index = i > 0 ? i+1 : "";
            const labelIndex = index!=""?(index-1):index;
            const matches = data.name.match(/(\d+)/);
              const index1 = matches != null && matches[0] !='1'? matches[0] :"";
          const nonBagDelayEMp = ["typeOfExpenseBagDelayEMP","expensesForBagDelayEMP","expenseDesClaimBagDelayEmp", "purchaseDateBagDelayEmp", "amountBagDelayEmp"];
          nonBagDelayEMp.forEach((item, i) => {
            arr1.push({
              "questionText": this.getData(item + index1, "label", this.step2Name)['label'] ,
              "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
              "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
              "answer": this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
            });
          });
          let headIndex = index != ""? index :1
  
          claimAmountObj.push({
            "featureTypeText":$localize`:@@expense:Expense #` + headIndex ,
            "featureTypeCd": "expenseNo" + headIndex ,
            "questionAnswer": arr1
  
           })
          })
         }
         if(delayLossData =='2'){
          const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountBaggageDelayLostEMP").fields;
          singleClaimAmountDataMain.forEach((data, i) => {
            let arr1 = []
            const index = i > 0 ? i+1 : "";
            const labelIndex = index!=""?(index-1):index;
            const matches = data.name.match(/(\d+)/);
              const index1 = matches != null && matches[0] !='1'? matches[0] :"";
          const nonBagDelayEMPLost = ["itemDesBagDelayLostEmp","expsForBagDelayLostEMP","purDateBagDelayLostEmp", "amountBagDelayLostEmp"];
          nonBagDelayEMPLost.forEach((item, i) => {
            arr1.push({
              "questionText": this.getData(item + index1, "label", this.step2Name)['label'] ,
              "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
              "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
              "answer": this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
            });
          });
          let headIndex = index != ""? index :1
  
          claimAmountObj.push({
            "featureTypeText":$localize`:@@expense:Expense #` + headIndex ,
            "featureTypeCd": "expenseNo" + headIndex ,
            "questionAnswer": arr1
  
          })
         })
        }
         let confLost = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimBaggageDelayConfLostEMP").name;

       if(delayLossData =='3' && confLost== "addClaimBaggageDelayConfLostEMP"){

        const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimBaggageDelayConfLostEMP").fields;
         singleClaimAmountDataMain.forEach((data, i) => {
          let arr1 = []
          const index = i > 0 ? i + 1 : "";
          const labelIndex = index != "" ? (index - 1) : index;
          const matches = data.name.match(/(\d+)/);
          const index1 = matches != null && matches[0] !='1'? matches[0] :"";
        const nonBagDelayConfBagEMp = ["typeOfExpBagDelayConfLostEMP","expensesForBagDelayConfLostEMP","expenseDesBagDelayConfLostEmp", "purchaseDateBagDelayConfLostEmp","amountBagDelayConfLostEmp"];
        nonBagDelayConfBagEMp.forEach((item, i) => {
          arr1.push({
            "questionText": this.getData(item + index1, "label", this.step2Name)['label'] ,
            "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
            "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
            "answer": this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
          });
        });
        
        let headIndex = index != ""? index :1

        claimAmountObj.push({
          "featureTypeText":$localize`:@@expense:Expense #` + headIndex ,
          "featureTypeCd": "expenseNo" + headIndex ,
          "questionAnswer": arr1

        })
      })
      }
      let confirmedLost = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimBagDelayConfLostEMP").name;
     
      if(delayLossData =='3' && confirmedLost=="addClaimBagDelayConfLostEMP"){
        
        const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimBagDelayConfLostEMP").fields;
        singleClaimAmountDataMain.forEach((data, i) => {
         let arr1 = []
        const index = i > 0 ? i + 1 : "";
        const labelIndex = index != "" ? (index - 1) : index;
        const matches = data.name.match(/(\d+)/);
          const index1 = matches != null && matches[0] !='1'? matches[0] :""; 
        const nonBagDelayConfLostEMp = ["ItemDescBagDelayConfLostEmp","expForBagDelayConfLostEMP","purDateBagDelayConfLostEmp","amtBagDelayConfLostEmp"]
        nonBagDelayConfLostEMp.forEach((item, i) => {
          arr1.push({
            "questionText": this.getData(item + index1, "label", this.step2Name)['label'] ,
            "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
            "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
            "answer": this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
          });
        });
       
        let headIndex = index != ""? index :1
        claimAmountObj.push({
          "featureTypeText":$localize`:@@expense:Expense #` + headIndex ,
          "featureTypeCd": "expenseNo" + headIndex ,
          "questionAnswer": arr1

        })
        })
       }
        
    }
    this.claimTypeDetails.claimAmount= this.claimTypeDetails.claimAmount.concat(claimAmountObj);
    return claimAmountObj;
  }
  getClaimAmountTravelMedicalData() {
    const claimAmountObj=[];
    this.claimTypeDetails.claimAmount =[];
    // Claim Amount Expense

      if (this.uiData["claimAmountType"] == "singleWithoutEmployer") {
        const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountTravelMedicalNoEmpMain").fields;
        singleClaimAmountDataMain.forEach((data, i) => {
          let arr1 = []
          const index = i > 0 ? i + 1 : "";
          const labelIndex = index != "" ? (index - 1) : index;
          const nonBagNoEMp = ["typeOfExpenseTravelMedicalNoEmp","expenseDescriptionTravelMedicalNoEmp","dateExpenseTravelMedicalNoEmp", "amountTravelMedicalNoEmp"];
          
          nonBagNoEMp.forEach((item, i) => {
            if(item === "typeOfExpenseTravelMedicalNoEmp"){
              if(this.uiData["curtailed"] === "Yes"){
                arr1.push({
                  "questionText": this.getData(item + index, "label", this.step2Name)['label'] ,
                  "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
                  "answerCd": this.getData(item + index, "", this.step2Name)?.rpaValue,
                  "answer":  this.getData(item + index, "", this.step2Name)?.name
                });
              }
            }
            else{
              arr1.push({
                "questionText": this.getData(item + index, "label", this.step2Name)['label'] ,
                "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
                "answerCd": null,
                "answer":  this.checkCustom(item,index,this.step2Name)
              });
          }
          });
          let headIndex = index != ""? index :1

          claimAmountObj.push({
            "featureTypeText":$localize`:@@expense:Expense #` + headIndex ,
            "featureTypeCd": "expenseNo" + headIndex ,
            "questionAnswer": arr1

          })
        })

      }
      else if (this.uiData["claimAmountType"] == "singleWithEmployer") {
        const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountTravelMedicalWithEmpMain").fields;
        singleClaimAmountDataMain.forEach((data, i) => {
          let arr1 = []
          const index = i > 0 ? i+1 : "";
          const labelIndex = index!=""?(index-1):index;
          const nonBagEMp = ['typeOfExpenseTravelMedicalWithEmp','expenseDescriptionTravelMedicalWithEmp','empPrivateTravelMedical', 'dateExpenseTravelMedicalWithEmp', 'amountTravelMedicalEmp'];
          nonBagEMp.forEach((item, i) => {
            if(item === "typeOfExpenseTravelMedicalWithEmp"){
              if(this.uiData["curtailed"] === "Yes"){
                arr1.push({
                  "questionText": this.getData(item + index, "label", this.step2Name)['label'] ,
                  "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
                  "answerCd": this.getData(item + index, "", this.step2Name)?.rpaValue,
                  "answer":  this.getData(item + index, "", this.step2Name)?.name
                });
              }
            }
            else{
            arr1.push({
              "questionText": this.getData(item + index, "label", this.step2Name)['label'],
              "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
              "answerCd":this.getData(item + index, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index, "", this.step2Name)?.rpaValue:null,
              "answer": this.getData(item + index, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index, "", this.step2Name)?.name:this.checkCustom(item,index,this.step2Name)
            });
          }
          });
          let headIndex = index != ""? index :1

          claimAmountObj.push({
            "featureTypeText": $localize`:@@expense:Expense #` + headIndex ,
            "featureTypeCd": "expenseNo" + headIndex,
            "questionAnswer": arr1
          })
        })
      }
    
    this.claimTypeDetails.claimAmount= this.claimTypeDetails.claimAmount.concat(claimAmountObj);
    return claimAmountObj;
  }

  getClaimAmountTripCancelData(){
    const claimAmountObj=[];
    this.claimTypeDetails.claimAmount =[];
    
    if (this.selectedData == "singleWithoutEmployer") {

      const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountTripCancel").fields;
      singleClaimAmountDataMain.forEach((data, i) => {
        let arr1 = []
        const index = i > 0 ? i + 1 : "";
        const labelIndex = index != "" ? (index - 1) : index;
        const matches = data.name.match(/(\d+)/);
          const index1 = matches != null && matches[0] !='1'? matches[0] :"";
        const nonTripCancelNoEMp = ["typeOfExpenseTripCancelNoEMP","expenseDesTripCancelNoEmp","purchaseDateTripCancelNoEmp", "amountTripCancelNoEmp"];
        const cancelPurchaseCheckTripCancelFalseNoEmp =["cancellationDateTripCancelNoEmp","refundReceivedTripCancelNoEmp","cancelPurchaseCheckTripCancelNoEmp"];
        
        nonTripCancelNoEMp.forEach((item, i) => {
          arr1.push({
            "questionText": this.getData(item + index1, "label", this.step2Name)['label'] ,
            "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
            "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
            "answer": this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
          });
        })
                  
        if(this.uiData['cancelPurchaseCheckTripCancelNoEmp' +index1] == null){
          cancelPurchaseCheckTripCancelFalseNoEmp.forEach((item, i) => {            
              arr1.push({
              "questionText": this.getData(item + index1, "label", this.step2Name)['label'] ,
              "questionCd": this.getData(item + index1, "label", this.step2Name)['questionCd'],
              "answerCd":this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.rpaValue:null,
              "answer": this.getData(item + index1, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index1, "", this.step2Name)?.name:this.checkCustom(item,index1,this.step2Name)
            });
          }) 
        }
        else{
          arr1.push({
            "questionText": this.getData('cancelPurchaseCheckTripCancelNoEmp' +index1, "label", this.step2Name)['label'] ,
            "questionCd": this.getData('cancelPurchaseCheckTripCancelNoEmp' +index1, "label", this.step2Name)['questionCd'],
            "answerCd":null,
            "answer": this.checkCustom('cancelPurchaseCheckTripCancelNoEmp',index1,this.step2Name)
          });
        } 
              
        let typeOfExpenseTripCancelNoEMP=this.getData("typeOfExpenseTripCancelNoEMP" + index1, "", this.step2Name)?.rpaValue;
        if(typeOfExpenseTripCancelNoEMP==="Prepaid airline tickets"){
          arr1.push({
            "questionText": this.getData("totalFeesTripCancelNoEmp" + index1, "label", this.step2Name)['label'] ,
            "questionCd": this.getData("totalFeesTripCancelNoEmp" + index1, "label", this.step2Name)['questionCd'],
            "answerCd":this.getData("totalFeesTripCancelNoEmp" + index1, "label", this.step2Name)['type']==="dropdown"?this.getData("totalFeesTripCancelNoEmp" + index1, "", this.step2Name)?.rpaValue:null,
            "answer": this.getData("totalFeesTripCancelNoEmp" + index1, "label", this.step2Name)['type']==="dropdown"?this.getData("totalFeesTripCancelNoEmp" + index1, "", this.step2Name)?.name:this.checkCustom("totalFeesTripCancelNoEmp",index1,this.step2Name)
          });
        }        
        
        let headIndex = index != ""? index :1

        claimAmountObj.push({
          "featureTypeText":$localize`:@@expense:Expense #` + headIndex ,
          "featureTypeCd": "expenseNo" + headIndex ,
          "questionAnswer": arr1

        })
      })

    }
    else if (this.selectedData == "singleWithEmployer") {
      const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountTripCancelEMP").fields;
      singleClaimAmountDataMain.forEach((data, i) => {
        let arr1 = []
        const index = i > 0 ? i+1 : "";
        const labelIndex = index!=""?(index-1):index;
        const nonTripCancelEMp = ['typeOfExpenseTripCancelEMP','expenseDesClaimTripCancelEmp','empPrivateTripCancelEmp','purchaseDateTripCancelEmp', 'purchasePriceTripCancelEmp'];
        const cancelPurchaseCheckTripCancelFalseEmp =["cancellationDateTripCancelEmp","refundReceivedTripCancelEmp","cancelPurchaseCheckTripCancelEmp"];
        
        nonTripCancelEMp.forEach((item, i) => {
          arr1.push({
            "questionText": this.getData(item + index, "label", this.step2Name)['label'],
            "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
            "answerCd":this.getData(item + index, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index, "", this.step2Name)?.rpaValue:null,
            "answer": this.getData(item + index, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index, "", this.step2Name)?.name:this.checkCustom(item,index,this.step2Name)
          });
        });

        if(this.uiData['cancelPurchaseCheckTripCancelEmp' +index] == null){
          cancelPurchaseCheckTripCancelFalseEmp.forEach((item, i) => {            
              arr1.push({
              "questionText": this.getData(item + index, "label", this.step2Name)['label'] ,
              "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
              "answerCd":this.getData(item + index, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index, "", this.step2Name)?.rpaValue:null,
              "answer": this.getData(item + index, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index, "", this.step2Name)?.name:this.checkCustom(item,index,this.step2Name)
            });
          }) 
        }
        else{
          arr1.push({
            "questionText": this.getData('cancelPurchaseCheckTripCancelEmp' +index, "label", this.step2Name)['label'] ,
            "questionCd": this.getData('cancelPurchaseCheckTripCancelEmp' +index, "label", this.step2Name)['questionCd'],
            "answerCd":null,
            "answer": this.checkCustom('cancelPurchaseCheckTripCancelEmp',index,this.step2Name)
          });
        } 
        let typeOfExpenseTripCancelNoEMP=this.getData("typeOfExpenseTripCancelEMP" + index, "", this.step2Name)?.rpaValue;
        if(typeOfExpenseTripCancelNoEMP==="Prepaid airline tickets"){
          arr1.push({
            "questionText": this.getData("totalFeesTripCancelEmp" + index, "label", this.step2Name)['label'] ,
            "questionCd": this.getData("totalFeesTripCancelEmp" + index, "label", this.step2Name)['questionCd'],
            "answerCd":this.getData("totalFeesTripCancelEmp" + index, "label", this.step2Name)['type']==="dropdown"?this.getData("totalFeesTripCancelEmp" + index, "", this.step2Name)?.rpaValue:null,
            "answer": this.getData("totalFeesTripCancelEmp" + index, "label", this.step2Name)['type']==="dropdown"?this.getData("totalFeesTripCancelEmp" + index, "", this.step2Name)?.name:this.checkCustom("totalFeesTripCancelEmp",index,this.step2Name)
          });
        }
        let headIndex = index != ""? index :1

        claimAmountObj.push({
          "featureTypeText": $localize`:@@expense:Expense #` + headIndex ,
          "featureTypeCd": "expenseNo" + headIndex,
          "questionAnswer": arr1
        })
      })
    }
    this.claimTypeDetails.claimAmount= this.claimTypeDetails.claimAmount.concat(claimAmountObj);
    return claimAmountObj;
  }
  // getClaimAmountData() {
  //   const claimAmountObj=[];
  //   this.claimTypeDetails.claimAmount =[];
  //   // Claim Amount Expense
  //  // if (this.uiData["isMultiLossTypes"] === false) {
  //     if (this.uiData["claimAmountType"] == "singleWithoutEmployer" && this.dataStoreService.get('fnol-landing').uiStructure[0].value ==="Baggage damage") {
  //       const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountBaggageDamage").fields;
  //       singleClaimAmountDataMain.forEach((data, i) => {
  //         let arr1 = []
  //         const index = i > 0 ? i + 1 : "";
  //         const labelIndex = index != "" ? (index - 1) : index;
  //         const nonBagNoEMp = ["expenseDescriptionNoBagNoEmp","purchaseDateBagWithoutEmp", "amountNoBagNoEmp"];
  //         nonBagNoEMp.forEach((item, i) => {
  //           arr1.push({
  //             "questionText": this.getData(item + index, "label", this.step2Name)['label'] ,
  //             "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
  //             "answerCd": null,
  //             "answer":  this.checkCustom(item,index,this.step2Name)
  //           });
  //         });
  //         let headIndex = index != ""? index :1

  //         claimAmountObj.push({
  //           "featureTypeText":$localize`:@@expense:Expense #` + headIndex ,
  //           "featureTypeCd": "expenseNo" + headIndex ,
  //           "questionAnswer": arr1

  //         })
  //       })

  //     }
  //    else if (this.uiData["claimAmountType"] == "singleWithEmployer" && this.dataStoreService.get('fnol-landing').uiStructure[0].value ==="Baggage damage") {
  //       const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountBagEmpMain").fields;
  //       singleClaimAmountDataMain.forEach((data, i) => {
  //         let arr1 = []
  //         const index = i > 0 ? i+1 : "";
  //         const labelIndex = index!=""?(index-1):index;
  //         const nonBagEMp = ['expenseDescriptionClaimLabelBagEmp','purchasePriceBagEmp', 'purchaseDateBagEmp', 'empPrivateBagEmp'];
  //         nonBagEMp.forEach((item, i) => {
  //           arr1.push({
  //             "questionText": this.getData(item + index, "label", this.step2Name)['label'],
  //             "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
  //             "answerCd":this.getData(item + index, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index, "", this.step2Name)?.rpaValue:null,
  //             "answer": this.getData(item + index, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index, "", this.step2Name)?.name:this.checkCustom(item,index,this.step2Name)
  //           });
  //         });
  //         let headIndex = index != ""? index :1

  //         claimAmountObj.push({
  //           "featureTypeText": $localize`:@@expense:Expense #` + headIndex ,
  //           "featureTypeCd": "expenseNo" + headIndex,
  //           "questionAnswer": arr1
  //         })
  //       })
  //     }

  //     if (this.uiData["claimAmountType"] == "singleWithoutEmployer" && this.dataStoreService.get('fnol-landing').uiStructure[0].value ==="Baggage theft") {
  //       const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountBaggageTheft").fields;
  //       singleClaimAmountDataMain.forEach((data, i) => {
  //         let arr1 = []
  //         const index = i > 0 ? i + 1 : "";
  //         const labelIndex = index != "" ? (index - 1) : index;
  //         const nonBagNoEMp = ["expenseDesClaimBagTheftNoEmp","purchaseDateBagTheftNoEmp", "amountBagTheftNoEmp"];
  //         nonBagNoEMp.forEach((item, i) => {
  //           arr1.push({
  //             "questionText": this.getData(item + index, "label", this.step2Name)['label'] ,
  //             "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
  //             "answerCd": null,
  //             "answer":  this.checkCustom(item,index,this.step2Name)
  //           });
  //         });
  //         let headIndex = index != ""? index :1

  //         claimAmountObj.push({
  //           "featureTypeText":$localize`:@@expense:Expense #` + headIndex ,
  //           "featureTypeCd": "expenseNo" + headIndex ,
  //           "questionAnswer": arr1

  //         })
  //       })

  //     }
  //    else if (this.uiData["claimAmountType"] == "singleWithEmployer" && this.dataStoreService.get('fnol-landing').uiStructure[0].value ==="Baggage theft") {
  //       const singleClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountBagTheftEMP").fields;
  //       singleClaimAmountDataMain.forEach((data, i) => {
  //         let arr1 = []
  //         const index = i > 0 ? i+1 : "";
  //         const labelIndex = index!=""?(index-1):index;
  //         const nonBagEMp = ['expenseDesClaimBagTheftEmp','purchaseDateBagTheftEmp', 'purchasePriceBagTheftEmp', 'empPrivateBagTheftEmp'];
  //         nonBagEMp.forEach((item, i) => {
  //           arr1.push({
  //             "questionText": this.getData(item + index, "label", this.step2Name)['label'],
  //             "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
  //             "answerCd":this.getData(item + index, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index, "", this.step2Name)?.rpaValue:null,
  //             "answer": this.getData(item + index, "label", this.step2Name)['type']==="dropdown"?this.getData(item + index, "", this.step2Name)?.name:this.checkCustom(item,index,this.step2Name)
  //           });
  //         });
  //         let headIndex = index != ""? index :1

  //         claimAmountObj.push({
  //           "featureTypeText": $localize`:@@expense:Expense #` + headIndex ,
  //           "featureTypeCd": "expenseNo" + headIndex,
  //           "questionAnswer": arr1
  //         })
  //       })
  //     }
  //   //} 
  //   // else {
  //   //   const multiClaimAmountDataMain = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2Name).uiStructure, "addClaimAmountMultipleBagEmpMain").fields;
  //   //   multiClaimAmountDataMain.forEach((data, i) => {
  //   //     let arr1 = []
  //   //     const index = i > 0 ? i+1 : "";
  //   //     const labelIndex = index!=""?(index-1):index;
  //   //     const commonFields = ['relevantCauseMultipleBagEmp'];
  //   //     commonFields.forEach((item, i) => {

  //   //       arr1.push({
  //   //         "questionText": this.getData(item + index, "label", this.step2Name)['label'],
  //   //         "questionCd": this.getData(item + index, "label", this.step2Name)['questionCd'],
  //   //         "answerCd":this.getData(item + index, "", this.step2Name).rpaValue,
  //   //         "answer": this.getData(item + index, "label", this.step2Name)['type']==="dropdown"?this.checkCustom(item,index,this.step2Name)?.name:this.checkCustom(item,index,this.step2Name)
  //   //       });
  //   //     });

  //   //        if (this.uiData["claimAmountType" + index] == "multiWithEmployer") {
  //   //         const nonBagEmp = ['expenseDescriptionMultipleNoBagEmp','purchaseDateMultiWithEmp', 'amountMultipleNoBagEmp','employerPrivateMultipleNoBagEmp'];
  //   //         nonBagEmp.forEach((field, i) => {
  //   //           arr1.push({
  //   //             "questionText": this.getData(field + index, "label", this.step2Name)['label'],
  //   //             "questionCd": this.getData(field + index, "label", this.step2Name)['questionCd'],
  //   //             "answerCd":this.getData(field + index, "label", this.step2Name)['type']==="dropdown"?this.getData(field + index , "", this.step2Name)?.rpaValue:null,
  //   //             "answer": this.getData(field + index, "label", this.step2Name)['type']==="dropdown"?this.getData(field + index, "", this.step2Name)?.name:this.checkCustom(field,index,this.step2Name)
  //   //           });
  //   //         });
  //   //       }
  //   //       else if (this.uiData["claimAmountType" + index] == "multiWithoutEmployer") {
  //   //         const bagNoEmp = ['expenseDescriptionMultipleNoBagNoEmp','purchaseDateMultiWithoutEmp', 'amountMultipleNoBagNoEmp'];
  //   //         bagNoEmp.forEach((field, i) => {
  //   //           arr1.push({
  //   //             "questionText": this.getData(field + index, "label", this.step2Name)['label'],
  //   //             "questionCd": this.getData(field + index, "label", this.step2Name)['questionCd'],
  //   //             "answerCd":this.getData(field + index, "label", this.step2Name)['type']==="dropdown"?this.getData(field + index, "", this.step2Name)?.rpaValue:null,
  //   //             "answer": this.getData(field + index, "label", this.step2Name)['type']==="dropdown"?this.getData(field +index, "", this.step2Name)?.name:this.checkCustom(field,index,this.step2Name)
  //   //           });
  //   //         });
  //   //       }
 

  //   //       let headIndex = index != ""? index :1
  //   //     claimAmountObj.push({
  //   //       "featureTypeText": $localize`:@@expense:Expense #` + headIndex,
  //   //       "featureTypeCd": "expenseNo" + headIndex ,
  //   //       "questionAnswer": arr1

  //   //     })

  //   //   })

  //   // }
  //   // const selectedClaimAmount =this.uiData["claimAmountType"].slice(this.uiData["isMultiLossTypes"]?5:6);
  //   // let claimAMountQuesionCd="";
  //   // let claimAmountAnswer="";
  //   // if(selectedClaimAmount=="BaggageLossWithEmployer"){
  //   //   claimAMountQuesionCd= "baggageEmployerExpense";
  //   //   claimAmountAnswer = "Baggage, Employer";
  //   // }else if(selectedClaimAmount=="BaggageLossWithoutEmployer"){
  //   //   claimAMountQuesionCd= "baggageNotEmployerExpense";
  //   //   claimAmountAnswer = "Baggage, Not Employer";
  //   // }else if(selectedClaimAmount=="NonBaggageLossWithEmployer"){
  //   //   claimAMountQuesionCd ="notBaggageEmployerExpense";
  //   //   claimAmountAnswer = "Not Baggage, Employer";
  //   // }else if(selectedClaimAmount=="NonBaggageLossWithoutEmployer"){
  //   //   claimAMountQuesionCd = "notBaggageNotEmployerExpense";
  //   //   claimAmountAnswer = "Not Baggage, Not Employer";
  //   // }
  //   // claimAmountObj.unshift(
  //   //   {
  //   //     "questionText": "Type of Expense:",
  //   //     "questionCd":claimAMountQuesionCd,
  //   //     "answerCd":null,
  //   //     "answer":"Expenses – " + claimAmountAnswer
  //   //   },
  //   // )
  //   this.claimTypeDetails.claimAmount= this.claimTypeDetails.claimAmount.concat(claimAmountObj);
  //   return claimAmountObj;
  // }
  getPayloadStep2() {
    let claimDetailsResult;
    this.claimDetailsInfo = [
          {
            "featureTypeText": $localize`:@@tripDetails:Trip Details`,
            "featureTypeCd": "tripDetails",
            "questionAnswer": this.getTripDetails()
          }
    ];
    this.nordicsApiService.lossDetails.subscribe((fields)=>{if(fields.length>0)this.lossTypesData=[...fields];});
    this.lossTypesData.length>0 && this.lossTypesData.forEach((item)=>{
      if(item.name === landingPageLabels[0]?.name){
        this.claimDetailsInfo.push({
          "featureTypeText": $localize`:@@bagDamDet:Baggage damage Details`,
          "featureTypeCd": 'baggageDamage',
          "questionAnswer":this.getBaggageDamageDetails()
        })
      }else if(item.name === landingPageLabels[1].name){
        this.claimDetailsInfo.push({
          "featureTypeText":$localize`:@@bagDelDet:Baggage delay / Loss of checked baggage Details`,
          "featureTypeCd": "baggageDelay",
          "questionAnswer":this.getDammageDelayDetails()
        })
      }else if(item.name === landingPageLabels[2].name){
        this.claimDetailsInfo.push({
          "featureTypeText": $localize`:@@bagtheftDet:Baggage theft Details`,
          "featureTypeCd": "baggageTheft",
          "questionAnswer":this.getTheftDetails()
        })
      }else if(item.name === landingPageLabels[3].name){
        this.claimDetailsInfo.push({
          "featureTypeText": $localize`:@@carRental:Car rental / Collision damage waiver Details`,
          "featureTypeCd": "carRental",
          "questionAnswer":this.getRentalDetailsData()
        })
      }else if(item.name === landingPageLabels[4].name){
        this.claimDetailsInfo.push({
          "featureTypeText": $localize`:@@travelMedExp:Travel medical expenses Details`,
          "featureTypeCd": "travelMedicalExpenses",
          "questionAnswer":this.getTravelMedicalData()
        })
      }else if(item.name === landingPageLabels[5].name){
        this.claimDetailsInfo.push({
          "featureTypeText":$localize`:@@tripCanDetails:Trip cancellation Details`,
          "featureTypeCd": "tripCancellation",
          "questionAnswer": this.getTripCancelData()
        })
      }else if(item.name === landingPageLabels[6].name){
        this.claimDetailsInfo.push({
          "featureTypeText": $localize`:@@tripdelDetails:Trip delay Details`,
          "featureTypeCd": "tripDelay",
          "questionAnswer": this.getTripDelayData()
        })
      }


    });

    claimDetailsResult =	{
			"featureTypeText": $localize`:@@claimDetails:Claim Details`,
			"featureTypeCd": "claimDetails",
			"claimDetails": this.claimDetailsInfo }



    return claimDetailsResult;

  }
  getInsuranceInfo() {
    let uiData = this.dataStoreService.get(this.step1Name).uiData

    if (uiData.chooseYourself === "Credit Card") return this.getCreditCard()

    else if (uiData.chooseYourself === "Union / Association") return this.getUnionInfo()
    else if (uiData.chooseYourself === "Purchased Myself") return this.getMyself()
    else if (uiData.chooseYourself === "Employer") return this.getEmployer()

  }

  recursiveData(dataArr, uiData, result = []) {
    // var r = result;
    dataArr.forEach((field, i) => {
      let value = field;
      if (value.type === "text" || value.type === "radio" || value.type === "dropdown" || value.type === "datepicker" ||value.type === "custom" ||
       value.type === "checkbox" || value.type === "textarea" || value.type === "timepicker") {
        if(value.type === "datepicker"){
          this.uiData[value.name] = (this.uiData[value.name]!=null || this.uiData[value.name]!="") ? moment(this.uiData[value.name]).format('DD-MM-YYYY'):this.uiData[value.name];
        }
        result.push(value);


        // r.push(value);
      } else if (value.type === "container") {
        value = value.fields;
        this.recursiveData(value, uiData, result);
      }
    });

    return result;
  }

  getPaymentData() {
    let paymentObj = []
    let paymentObj2 = []
    let norField = ["accountOwnerNameN", "bankNameN"]
    let foreignField = ["accountOwnerNameF", "bankNameF"]
    let norField2 = ["accountOwnerNameN2", "bankNameN2"]
    let foreignField2 = ["accountOwnerNameF2", "bankNameF2"]

    let language= window.location.pathname.split('/')[4];
    let norBank;
    let forBank;
    if(language  === "en"){
      norBank="Norwegian Bank Account";
      forBank="Foreign Bank Account";
    }
    else{
      norBank="Norsk bankkonto";
      forBank="Utenlandsk bankkonto";
    }

    let finalPaymentobj =[]



    if (this.getData("bankType", '', this.step3Name) === norBank) {

      let bankType = {
        "questionText": $localize`:@@bankType:Bank Type`,
					"questionCd": "bankType",
				"answerCd":null,
        "answer": this.getData("bankType", '', this.step3Name)
      }
      paymentObj.push(bankType)



      norField.forEach((i) => {
        paymentObj.push(
          {
            "questionText": this.getData(i, "label", this.step3Name)['label'],
            "questionCd": this.getData(i, "label", this.step3Name)['questionCd'],
            "answer": this.getData(i, "", this.step3Name),
            "answerCd": null,

          })
      })

      let accountNum = {
        "questionText": $localize`:@@accountNumberNor:Account Number`,
        "questionCd": "accountNumber",
        "answerCd":null,
        "answer": this.getMaksing(this.getData("accountNumberN", '', this.step3Name))
      }
      paymentObj.push(accountNum)

      let accountNumConfirm = {
        "questionText": $localize`:@@confirmAccountNumberNor:Confirm Account Number`,
					"questionCd": "confirmAccountNumber",
				"answerCd":null,
        "answer": this.getMaksing(this.getData("confirmAccountNumberN", '', this.step3Name))
      }
      paymentObj.push(accountNumConfirm)
      
      let accountMailAddN = {
        "questionText": this.getData("accountMailAddN", 'label', this.step3Name)["label"],
        "questionCd": "accountMailAddNorwegian",
        "answerCd": null,
        "answer": this.getData("accountMailAddN", '', this.step3Name)
      }
      paymentObj.push(accountMailAddN)
      let postalCodeN = {
        "questionText": this.getData("postalCodeN", 'label', this.step3Name)["label"],
        "questionCd": "postalCodeNorwegianBank",
        "answerCd": null,
        "answer": this.getData("postalCodeN", '', this.step3Name)
      }
      paymentObj.push(postalCodeN)
      let cityN = {
        "questionText": this.getData("cityN", 'label', this.step3Name)["label"],
        "questionCd": "cityNorwegianBank",
        "answerCd": null,
        "answer": this.getData("cityN", '', this.step3Name)
      }
      paymentObj.push(cityN)
      let countryN = {
        "questionText": this.getData("countryN", 'label', this.step3Name)["label"],
        "questionCd": "countryNameNorwegianBank",
        "answerCd":this.getData("countryN", '', this.step3Name).rpaValue,
        "answer": this.getData("countryN", '', this.step3Name)?.name 
      }
      paymentObj.push(countryN)


      finalPaymentobj.push({
        "featureTypeText": $localize`:@@paymentype1:Payment #1`,
        "featureTypeCd": "paymentType1",
        "questionAnswer": paymentObj
      })

    } else if (this.getData("bankType", '', this.step3Name) === forBank) {

      let bankType = {
        "questionText": $localize`:@@bankType:Bank Type`,
					"questionCd": "bankType",
				"answerCd":null,
        "answer": this.getData("bankType", '', this.step3Name)
      }
      paymentObj.push(bankType)


      foreignField.forEach((i) => {
        paymentObj.push(
          {
            "questionText": this.getData(i, "label", this.step3Name)['label'],
            "questionCd": this.getData(i, "label", this.step3Name)['questionCd'],
            "answer": this.getData(i, "", this.step3Name),
            "answerCd": null,
          })
      })

      let accountNum = {
        "questionText": $localize`:@@accountNumberNor:Account Number`,
        "questionCd": "accountNumber",
        "answerCd":null,
        "answer": this.getMaksing(this.getData("accountNumberF", '', this.step3Name))
      }
      paymentObj.push(accountNum)

      let accountNumConfirm = {
        "questionText": $localize`:@@confirmAccountNumberNor:Confirm Account Number`,
					"questionCd": "confirmAccountNumber",
				"answerCd":null,
        "answer": this.getMaksing(this.getData("confirmAccountNumberF", '', this.step3Name))
      }
      paymentObj.push(accountNumConfirm)


      let ibanApplicableF = {
        "questionText": this.getData("ibanApplicableF", 'label', this.step3Name)["label"],
        "questionCd": "ibanApplicableForeignBank",
        "answerCd": null,
        "answer": this.getData("ibanApplicableF", '', this.step3Name)
      }
      paymentObj.push(ibanApplicableF)

      let bicSwiftF = {
        "questionText": this.getData("bicSwiftF", 'label', this.step3Name)["label"],
        "questionCd": "bicSwiftForeignBank",
        "answerCd": null,
        "answer": this.getData("bicSwiftF", '', this.step3Name)
      }
      paymentObj.push(bicSwiftF)
      let accountMailAddF = {
        "questionText": this.getData("accountMailAddF", 'label', this.step3Name)["label"],
        "questionCd": "accountMailAddForeign",
        "answerCd": null,
        "answer": this.getData("accountMailAddF", '', this.step3Name)
      }
      paymentObj.push(accountMailAddF)
      let postalCodeF = {
        "questionText": this.getData("postalCodeF", 'label', this.step3Name)["label"],
        "questionCd": "postalCodeForeignBank",
        "answerCd": null,
        "answer": this.getData("postalCodeF", '', this.step3Name)
      }
      paymentObj.push(postalCodeF)
      let cityF = {
        "questionText": this.getData("cityF", 'label', this.step3Name)["label"],
        "questionCd": "cityForeignBank",
        "answerCd": null,
        "answer": this.getData("cityF", '', this.step3Name)
      }
      paymentObj.push(cityF)
      let countryF = {
        "questionText": this.getData("countryF", 'label', this.step3Name)["label"],
        "questionCd": "countryNameForeignBank",
        "answerCd":this.getData("countryF", '', this.step3Name).rpaValue,
        "answer": this.getData("countryF", '', this.step3Name)?.name ? this.getData("countryF", '', this.step3Name)?.name : this.getData("countryF", '', this.step3Name)
      }
      paymentObj.push(countryF)

      finalPaymentobj.push({
        "featureTypeText": $localize`:@@paymentype1:Payment #1`,
        "featureTypeCd": "paymentType1",
        "questionAnswer": paymentObj
      })
    }

    if (this.getData("isPaymentDuplicate", '', this.step3Name)) {
      if (this.getData("bankType2", '', this.step3Name) === norBank) {

        let bankType = {
          "questionText":  $localize`:@@bankType:Bank Type`,
            "questionCd": "bankType",
          "answerCd":null,
          "answer": this.getData("bankType2", '', this.step3Name)
        }
        paymentObj2.push(bankType)


        norField2.forEach((i) => {
          paymentObj2.push(
            {
              "questionText": this.getData(i, "label", this.step3Name)['label'],
              "questionCd": this.getData(i, "label", this.step3Name)['questionCd'],
              "answer": this.getData(i, "", this.step3Name),
              "answerCd": null,
            })
        })

        let accountNum = {
          "questionText": $localize`:@@accountNumberNor:Account Number`,
          "questionCd": "accountNumber",
          "answerCd":null,
          "answer": this.getMaksing(this.getData("accountNumberN2", '', this.step3Name))
        }
        paymentObj2.push(accountNum)

        let accountNumConfirm = {
          "questionText": $localize`:@@confirmAccountNumberNor:Confirm Account Number`,
            "questionCd": "confirmAccountNumber",
          "answerCd":null,
          "answer": this.getMaksing(this.getData("confirmAccountNumberN2", '', this.step3Name))
        }
        paymentObj2.push(accountNumConfirm)

        let accountMailAddN2 = {
          "questionText": this.getData("accountMailAddN2", 'label', this.step3Name)["label"],
          "questionCd": "accountMailAddNorwegian",
          "answerCd": null,
          "answer": this.getData("accountMailAddN2", '', this.step3Name)
        }
        paymentObj2.push(accountMailAddN2)
        let postalCodeN2 = {
          "questionText": this.getData("postalCodeN2", 'label', this.step3Name)["label"],
          "questionCd": "postalCodeNorwegianBank",
          "answerCd": null,
          "answer": this.getData("postalCodeN2", '', this.step3Name)
        }
        paymentObj2.push(postalCodeN2)
        let cityN2 = {
          "questionText": this.getData("cityN2", 'label', this.step3Name)["label"],
          "questionCd": "cityNorwegianBank",
          "answerCd": null,
          "answer": this.getData("cityN2", '', this.step3Name)
        }
        paymentObj2.push(cityN2)
        let countryN2 = {
          "questionText": this.getData("countryN2", 'label', this.step3Name)["label"],
          "questionCd": "countryNameNorwegianBank",
          "answerCd":this.getData("countryN2", '', this.step3Name).rpaValue,
          "answer": this.getData("countryN2", '', this.step3Name).name ? this.getData("countryN2", '', this.step3Name).name : this.getData("countryN2", '', this.step3Name)

        }
        paymentObj2.push(countryN2)

      finalPaymentobj.push({
        "featureTypeText": $localize`:@@paymentype2:Payment #2`,
        "featureTypeCd": "paymentType2",
        "questionAnswer": paymentObj2
      })

      } else if (this.getData("bankType2", '', this.step3Name) === forBank) {

        let bankType = {
          "questionText": $localize`:@@bankType:Bank Type`,
            "questionCd": "bankType",
          "answerCd":null,
          "answer": this.getData("bankType2", '', this.step3Name)
        }
        paymentObj2.push(bankType)

        foreignField2.forEach((i) => {

          paymentObj2.push(
            {
              "questionText": this.getData(i, "label", this.step3Name)['label'],
              "questionCd": this.getData(i, "label", this.step3Name)['questionCd'],
              "answer": this.getData(i, "", this.step3Name),
              "answerCd": null,
            })
        })

        let accountNum = {
          "questionText": $localize`:@@accountNumberNor:Account Number`,
          "questionCd": "accountNumber",
          "answerCd":null,
          "answer": this.getMaksing(this.getData("accountNumberF2", '', this.step3Name))
        }
        paymentObj2.push(accountNum)

        let accountNumConfirm = {
          "questionText": $localize`:@@confirmAccountNumberNor:Confirm Account Number`,
            "questionCd": "confirmAccountNumber",
          "answerCd":null,
          "answer": this.getMaksing(this.getData("confirmAccountNumberF2", '', this.step3Name))
        }
        paymentObj2.push(accountNumConfirm)

        // let checkhowToPaySomeOne = {
        //   "questionText": "If claiming for someone else, how should we send the claim payment to them?",
        //   "questionCd": "claimPaymentModeForSomeone",
        //   "answerCd": null,
        //   "answer": this.getData("bankType2", '', this.step3Name)
        // }
        // paymentObj2.push(checkhowToPaySomeOne)


        let ibanApplicableF2 = {
          "questionText": this.getData("ibanApplicableF2", 'label', this.step3Name)["label"],
          "questionCd": "ibanApplicableForeignBank",
          "answerCd": null,
          "answer": this.getData("ibanApplicableF2", '', this.step3Name)
        }
        paymentObj2.push(ibanApplicableF2)

        let bicSwiftF2 = {
          "questionText": this.getData("bicSwiftF2", 'label', this.step3Name)["label"],
          "questionCd": "bicSwiftForeignBank",
          "answerCd": null,
          "answer": this.getData("bicSwiftF2", '', this.step3Name)
        }
        paymentObj2.push(bicSwiftF2)
        let accountMailAddF2 = {
          "questionText": this.getData("accountMailAddF2", 'label', this.step3Name)["label"],
          "questionCd": "accountMailAddForeign",
          "answerCd": null,
          "answer": this.getData("accountMailAddF2", '', this.step3Name)
        }
        paymentObj2.push(accountMailAddF2)
        let postalCodeF2 = {
          "questionText": this.getData("postalCodeF2", 'label', this.step3Name)["label"],
          "questionCd": "postalCodeForeignBank",
          "answerCd": null,
          "answer": this.getData("postalCodeF2", '', this.step3Name)
        }
        paymentObj2.push(postalCodeF2)
        let cityF2 = {
          "questionText": this.getData("cityF2", 'label', this.step3Name)["label"],
          "questionCd": "cityForeignBank",
          "answerCd": null,
          "answer": this.getData("cityF2", '', this.step3Name)
        }
        paymentObj2.push(cityF2)
        let countryF2 = {
          "questionText": this.getData("countryF2", 'label', this.step3Name)["label"],
          "questionCd": "countryNameForeignBank",
          "answerCd":this.getData("countryF2", '', this.step3Name).rpaValue,
          "answer": this.getData("countryF2", '', this.step3Name).name ? this.getData("countryF2", '', this.step3Name).name : this.getData("countryF2", '', this.step3Name)

        }
        paymentObj2.push(countryF2)

      finalPaymentobj.push({
        "featureTypeText": $localize`:@@paymentype2:Payment #2`,
        "featureTypeCd": "paymentType2",
        "questionAnswer": paymentObj2
      })


      }

    }


    return  finalPaymentobj;


    }



  generateSetFnolRequestNordics() {

    let applicationContext = {
      "countryCode": window.location.pathname.split("/")[3].toUpperCase(),
      "language": window.location.pathname.split("/")[4],
      "lineOfBusiness": window.location.pathname.split("/")[5].toUpperCase(),
      "userId": this.config.userEmailID,
      "claimNumber": this.cacheService.getClaimNumber(),
      "subLob": "NA",
      "submissionDate": moment(new Date()).format("DD-MM-YYYY"),
      "submissionTime":  moment(new Date()).format("HH:mm:ss"),
      "countryName": "Norway",
      "product": "Travel"
    }

    return applicationContext;

  }
  getLandingDetails(){
    const landingData ={'lossData':this.dataStoreService.get('fnol-landing').uiStructure[0].value,'cause':this.dataStoreService.get('fnol-landing').uiStructure[1].value};
    return landingData;
  }
  getRequestPayload() {
   let uiData1= this.dataStoreService.get(this.step1Name).uiData
    let requestObj = {
      "applicationContext": this.generateSetFnolRequestNordics(),
      "lossType": [
        {
          "featureTypeText": $localize`:@@lostype:Loss Type`,
          "featureTypeCd": "lossType",
          "questionAnswer": [
            {
              "questionText": $localize`:@@whatHappen:What happened with your trip ?`,
              "questionCd": "lossType",
              "answerCd": null,
              "answer":this.getLandingDetails().lossData
            }
          ]
        }
      ],
      
      "personalInformation": [
        {
          "featureTypeText": $localize`:@@SummaryInfoNor:Personal Information`,
          "featureTypeCd": "personalInformation",
          "insuranceInfo": [
            {
              "featureTypeText": $localize`:@@insDet:Insurance Details`,
              "featureTypeCd": "insuranceDetails",
              "questionAnswer": this.getInsuranceInfo()
            }
          ],
          "personalDetailsInfo": this.getPersonalDetailsInfo()
        }

      ],
       "claimDetailsInfo": this.getPayloadStep2(),
    //   "expenseDetails": {
    //     "featureTypeText": $localize`:@@claimAmountNor:Claim Amount`,
		// "featureTypeCd": "expenseType",
    //     "expenses": this.getClaimAmountData()
    //   },

      "uploadedDocsInfo": {
        "featureTypeText": $localize`:@@docum:Documents`,
        "featureTypeCd": "documents",
        "questionAnswer": this.getUploadDetails()
      },
      "paymentType":this.getPaymentData(),
      "reviewAndSubmitInfo": {
        "title": $localize`:@@summaryReviewNor:Review and Submit the Claim`,
        "attribute": [
          {
            "name": "I have read and agree to the declarations and authorisation & by submitting this form, I have read and agree with the declarations and authorisation",
            "value": "Yes"
          }
        ]
      },
      "privacyInfo": {
        "title": "Privacy Policy and Terms of Use",
        "attribute": [
          {
            "name": "Privacy Policy:",
            "value": "Yes"
          },
          {
            "name": "Terms of Use:",
            "value": "Yes"
          },
          {
            "name": "By continuing with this form, I acknowledge and agree with both Privacy Policy and Terms of Use:",
            "value": "Yes"
          }
        ]
      },
      "correspondenceGenerationData": {
        "correspondenceItemID": "FNOL_AH",
        "userID": "O1111147",
        "requestApplicationID": "FNOL",
        "requestMessageID": this.cacheService.getClaimNumber(),
        "callerDistributionFax": "",
        "callerDistributionEMAIL": "",
        "claimantEmail": uiData1['personalEmail'] ,
        "brokerEmail": "",
        "sharedServiceEmail":  this.config.aigSharedeMailID['no'],
        "isNotificationClaim": "",
        "lossState": "Norway",
        "reportedByFirstName":  uiData1['personalFirstName'],
        "reportedByLastName": uiData1['personalLastName'] ,
        "mobile":this.getData("personalPhone", "", this.step1Name)['phoneCode']['label'] + "-"  + this.getData("personalPhone", "", this.step1Name)['phoneNumber'],
        "businessPhone": "",
        "originKey": "",
        "dateReported": "",
         "lossDate":  moment(this.getLossTypeDate()).format("DD-MM-YYYY"),
        "insuredName":  uiData1['personalFirstName'] + " "  +  uiData1['personalLastName'],
        "clientName": "AIG",
        "systemDate":  moment(new Date()).format("DD-MM-YYYY"),
        "applicationID": "EXTPOR-001",
        "emailLanguage": window.location.pathname.split("/")[4].toUpperCase(),
        "claimFormProduct": this.formProduct()
      }
    }
    if(this.lossTypesData?.length>1){
      requestObj.lossType[0].questionAnswer.push({
        "questionText": $localize`:@@didEvent:Did the events selected happen due to the same cause occurring on the same day?`,
        "questionCd": "isSameDayCircumstances",
        "answerCd": null,
        "answer":this.getLandingDetails().cause
      })
    }
    return requestObj;

  }
   
  formProduct(){
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "addPersonMain").fields
  let uiData = this.dataStoreService.get(this.step1Name).uiData
  let language= window.location.pathname.split('/')[4] === 'no'?"no":"en";
    if ( uiData['chooseYourself'] === "Purchased Myself" || uiData['chooseYourself'] === "Credit Card"  ){
      return "Travel Ind"
    } else if(uiData['chooseYourself'] === "Employer"  || uiData['chooseYourself'] ===  "Union / Association"  ) {
     return "Travel Group"
    }

  }



 checkCustom( field,index,step){
    if( this.getData(field + index, "label", step)['type']==="custom" &&
    this.getData(field + index, "label", step)['element']==="CurrencyDropdownComponent"
    ){
      return this.getData(field + index , "", step)['phoneNumber']?
      this.getData(field + index , "", step)['phoneCode'].hasOwnProperty('label')?
      this.getData(field + index , "", step)['phoneCode']['label']+ " "  + this.axisPipe.transform(this.getData(field + index , "", step)['phoneNumber'].toString(),this.mySettings51):
      this.getData(field + index , "", step)['phoneCode'] + " "  + this.axisPipe.transform(this.getData(field + index , "", step)['phoneNumber'].toString(),this.mySettings51)
       : null
    }
    else {
      return this.getData(field + index, "", step)  
    }
  }

  getMaksing(input){

    return input
  }
      
    getInsuredName(){
    return this.dataStoreService.get(this.step1Name).uiData['personalFirstName'] + " " + this.dataStoreService.get(this.step1Name).uiData['personalLastName']
  }
   
   getLossTypeDate(){
   return this.nordicsApiService.getLossTypeDate(this.dataStoreService.get(this.step2Name).uiData)

  }

   convertDateGMT(datePassed, dateFormat, zeroCheck?): string {
    const dateFormatted = moment(datePassed).format(dateFormat);
    if (zeroCheck && zeroCheck === 'zeroTime') {
      return dateFormatted + 'T000000.000 GMT';
    } else { return dateFormatted + 'T050000.000 GMT'; }
  }



}
